import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";

const Services_Wise = (props) => {
  const { datasets } = props;
  const [tabledata, setData] = useState([]);

  useEffect(() => {
    const mappedArray = datasets?.map((item) => {
      return {
        company_name: item.task_name,
        total:
          item.completed_company_task +
          item.not_started_company_task +
          item.initialised_company_task +
          item.in_the_process_company_task +
          item.pending_company_task,
        completed: item.completed_company_task,
        notstarted: item.not_started_company_task,
        initiated: item.initialised_company_task,
        intheprocess: item.in_the_process_company_task,
        dataispending: item.pending_company_task,
      };
    });

    setData(mappedArray);
  }, [datasets]);
  const columns = [
    {
      name: "company_name",
      label: "Employee",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "total",
      label: "Total Task",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "completed",
      label: "Completed",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "notstarted",
      label: "Not Started",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "initiated",
      label: "Initiated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "intheprocess",
      label: "In the Process",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dataispending",
      label: "Data is Pending from Client",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "company_handeled",
      label: "Companies",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    rowsPerPageOptions: [10, 60, 1000, 1500, 2000],
    rowsPerPage: 60,
    responsive: "standard",
    fixedSelectColumn: true,
    tablebodywidth: "auto",
  };
  return (
    <MUIDataTable
      title={"Services List"}
      data={tabledata}
      columns={columns}
      options={options}
    />
  );
};

export default Services_Wise;
