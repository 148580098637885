import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios from "axios";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Grid,
  TextField,
} from "@mui/material";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Loader from "../loader/loader";

const Task_Company = () => {
  const columns = [
    {
      name: "name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "date",
      label: "Date of Incorporation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 100, 500, 1000, 2000],
  };
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [all_companies, set_All_companies] = useState([]);
  const [table_datas, settable_Data] = useState([]);
  const [opene, setOpene] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [company_name, Setcompany_name] = useState("");
  const [company_type, Setcompany_type] = useState(null);
  const [date_of_incorporation, Setdate_of_incorporation] = useState(null);
  const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage
  const [formData, setFormData] = useState({
    company_name: "",
    company_type: "",
    date_of_inc: "",
    source: "One Time",
  });
  const handleFormDataChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  console.log("formdata", formData);
  const handleAddCompany = () => {
    if (accessToken) {
      const instance = axios.create({
        baseURL: "https://api.startupfino.in", // API base URL
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      instance.post("/company/create-company/", formData).then((response) => {
        console.log(response);
        fetchData2();
        toast.success("Company Added Successfully");
        setOpene(false);
      });
    }
  };
  const fetchData2 = async () => {
    const companies = await axios(
      "https://api.startupfino.in/company/all-companies/"
    );
    set_All_companies(companies.data);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const companies = await axios(
        "https://api.startupfino.in/company/all-companies/"
      );
      set_All_companies(companies.data);
      setLoading(false);
    };
    fetchData();
  }, []);
  const new_comp = all_companies?.filter((row) => row.source === "One Time");
  console.log("newm", new_comp);
  useEffect(() => {
    const data = new_comp?.map((row) => {
      return {
        name: row.company_name,
        type: row.company_type === null ? "NA" : row.company_type,
        date:
          row.date_of_inc == null
            ? "NA"
            : moment(row.date_of_inc).format("DD-MM-YYYY"),
        active: row.is_company_active === true ? "Active" : "Inactive",
      };
    });
    settable_Data(data);
  }, [all_companies]);
  console.log(all_companies, "all_companies");
  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpene(false);
  };
  console.log("ctyope", company_type);

  return (
    <>
      <Button
        variant="contained"
        style={{ background: "#229A16" }}
        onClick={handleClickOpene("paper")}
        sx={{ m: 2 }}
      >
        Add Company
      </Button>

      <Dialog
        open={opene}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Add One Time Task Company
            <CloseRoundedIcon
              onClick={() => setOpene(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Box
              sx={{
                margin: 1,
                justifyContent: "center",
                flexDirection: "column",
              }}
              display="flex"
            >
              <Grid container spacing={1.25}>
                <Grid item xs={12} sx={{ m: 1 }}>
                  <TextField
                    label="Company Name"
                    name="company_name"
                    value={company_name}
                    onChange={(e) => {
                      Setcompany_name(e.target.value);
                      formData.company_name = e.target.value;
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} lg={6} md={6} sx={{ m: 1 }}>
                  <Autocomplete
                    label="Company Type"
                    fullWidth
                    id="combo-box-demo"
                    value={company_type}
                    onChange={(e, newVal) => {
                      Setcompany_type(newVal);
                      formData.company_type = newVal;
                    }}
                    options={[
                      "Private Company",
                      "Public Company",
                      "LLP",
                      "Partnership Firm",
                      "Sole Proprietorship",
                      "Others",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Company Type" />
                    )}
                  />
                </Grid>
                <Grid item xs={5} lg={5} md={5} sx={{ m: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      name="due_date"
                      label="Date of Incorporation"
                      format="DD/MM/YYYY"
                      value={date_of_incorporation}
                      onChange={(newTo_date) => {
                        Setdate_of_incorporation(newTo_date);
                        formData.date_of_inc =
                          moment(newTo_date).format("YYYY-MM-DD");
                      }}
                      //   value={new Date(}
                      //   onChange={(newTo_date) => {
                      //     setDue_date(newTo_date);
                      //     formData.due_date = moment(newTo_date).format("YYYY-MM-DD");
                      //     handleFormDataChange;
                      //   }}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ margin: 1 }} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </DialogContentText>
        </DialogContent>
        <Box
          container
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ margin: 1 }}
        >
          <Button variant="contained" onClick={handleAddCompany}>
            Add Company
          </Button>
        </Box>
      </Dialog>
      {loading ? (
        <Loader />
      ) : (
        <MUIDataTable
          title={"One Time Task Company List"}
          data={table_datas}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};

export default Task_Company;
