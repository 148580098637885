import { BrowserRouter as Router } from "react-router-dom";
import MainRoutes from "./routes/MainRoute";
import { ConfirmProvider } from "material-ui-confirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <>
      <ConfirmProvider>
        <ToastContainer />
        <Router>
          <MainRoutes />
        </Router>
      </ConfirmProvider>
    </>
  );
}

export default App;
