import React from "react";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
// https://api.startupfino.in/task-manager/api/task-management/
import axios from "axios";
import { useEffect } from "react";
import { Chip, Box } from "@mui/material";
import { Typography } from "@mui/material";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ClippedDrawer from "../sidebar/sidebar_list";
import logo from "../Logo/startupfino.png";
import { CircularProgress } from "@mui/material";
import logos from "../Logo/loader.png";
import Loader from "../loader/loader";
import {
  AppBar,
  Toolbar,
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import { Button } from "@mui/material";
import AddTeam_Member from "./AddTeam_Member";
import EditTeamMember from "./EditTeam_Member";
import Delete_Member from "./Delete_TeamMem";
const Team_Name = () => {
  const [opene, setOpene] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpene(false);
  };
  const handleUpdate = () => {
    setLoading(true);
    axios
      .get("https://api.startupfino.in/task-manager/api/team-mates/")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      });
  };
  const columns = [
    {
      name: "team_id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "emp_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Joining Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "desg",
      label: "Designation",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "update",
      label: "Update",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box display="flex">
                <Update row={data} handleUpdate={handleUpdate} />
                <Delete row={data} handleUpdate={handleUpdate} />
              </Box>
            </>
          );
        },
      },
    },
  ];
  // wrte code for api call using axios
  const [datas, setData] = useState([]);
  const [table_datas, settable_Data] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("data", datas);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await axios(
        "https://api.startupfino.in/task-manager/api/team-mates/"
      );
      setData(result.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  //write code for maping data from api to table data
  const rush = datas?.map((row) => row);
  console.log("rush", rush);

  useEffect(() => {
    const data = datas?.map((row) => {
      return {
        team_id: row.emp_id,
        emp_name: row.name,
        phone: row.phone_numer,
        email: row.email_address,
        date:
          row.date_of_joining === null
            ? "NA"
            : moment(row.date_of_joining).format("DD/MM/YY"),
        desg: row.designation,
        update: row,
      };
    });
    settable_Data(data);
  }, [datas]);

  console.log("tabledata", table_datas);

  console.log(datas);

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    rowsPerPageOptions: [10, 100, 1000, 1500, 2000],
    rowsPerPage: 10,
    responsive: "standard",
    fixedSelectColumn: true,
    tableBodyHeight: window.screen.availHeight,
  };
  const data_dummy = [
    { team_id: "0001", emp_name: "Ajay Jadega", Date: "3rd August 2022" },
    { team_id: "0002", emp_name: "Sneha Sharma", Date: "15th October 2021" },
    { team_id: "0003", emp_name: "Rahul Kapoor", Date: "28th May 2023" },
    { team_id: "0004", emp_name: "Neha Patel", Date: "9th December 2022" },
    { team_id: "0005", emp_name: "Rajesh Gupta", Date: "22nd July 2023" },
  ];

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <ClippedDrawer />
        {loading ? (
          <Loader />
        ) : (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pr: 3,
              pt: 2,
              pl: 2,
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "green", mb: 2 }}
              onClick={handleClickOpene("paper")}
            >
              Add Employee
            </Button>
            <Dialog
              open={opene}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              fullWidth={true}
              maxWidth="sm"
            >
              <DialogTitle id="scroll-dialog-title">
                <Box
                  component="span"
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Add Employee
                  <CloseRoundedIcon
                    onClick={() => setOpene(false)}
                    style={{ color: "#ff5252" }}
                    boxShadow={1}
                  />
                </Box>
              </DialogTitle>
              <DialogContent dividers={scroll === "paper"}>
                <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                  <AddTeam_Member
                    handleClosee={handleClosee}
                    handleUpdate={handleUpdate}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
            <MUIDataTable
              title={"StartupFino Task"}
              data={table_datas}
              columns={columns}
              options={options}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Team_Name;
const Update = (props) => {
  const { row, handleUpdate } = props;
  const [opene, setOpene] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpene(false);
  };
  return (
    <>
      <IconButton
        onClick={handleClickOpene("paper")}
        aria-label="delete"
        color="primary"
      >
        <ModeEditIcon />
      </IconButton>
      <Dialog
        open={opene}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Edit Employee Data
            <CloseRoundedIcon
              onClick={() => setOpene(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <EditTeamMember
              handleClosee={handleClosee}
              row={row}
              handleUpdate={handleUpdate}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};
const Delete = (props) => {
  const { row, handleUpdate } = props;
  const [opene, setOpene] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpene(false);
  };
  return (
    <>
      <IconButton
        onClick={handleClickOpen("paper")}
        aria-label="delete"
        color="error"
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={opene}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Delete Employee Data
            <CloseRoundedIcon
              onClick={() => setOpene(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Delete_Member
              handleClosee={handleClosee}
              row={row}
              handleUpdate={handleUpdate}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};
