import React from "react";
import { useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ToastContainer,toast } from "react-toastify";
const AddTeam_Member = (props) => {
  const { handleClosee ,handleUpdate} = props;
  const [formData, setFormData] = useState({
    name: "",
    phone_numer: "",
    date: "",
    email_address:"",
    designation:""
  });
  const [Emp_name, setEmp_name] = useState();
  const [phone, setPhone] = useState();
  const [date, setDate] = useState(null);
  const [email,setEmail]=useState();
  const [designation,setDesignation]=useState();

  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };

  const dis = (e) => {
    var bodyFormData = new FormData();
    bodyFormData.append('emp_id','test_id')
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    const apiUrl =
    "https://api.startupfino.in/task-manager/api/team-mates/"
    axios
    .post(apiUrl, bodyFormData)
    .then((response) => {
      console.log("Task added successfully:", response.data);
      toast.success(`${Emp_name} added successfully`);
      handleUpdate();
      handleClosee();
    })
    .catch((error) => {
      // Handle errors that occur during the request
      console.error("Error adding task:", error);
    });
    handleClosee();
  };
 
  return (
    <>
      <Box
        sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
        display="flex"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="name"
              onChange={(e) => {
                setEmp_name(e.target.value);
                {
                  formData.name = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Emp_name}
              fullWidth
              label="Employee Name"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={6} lg={6} md={6} xl={6}>
            <TextField
              name="email_address"
              onChange={(e) => {
                setEmail(e.target.value);
                {
                  formData.email_address = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={email}
              fullWidth
              label="Email Address"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={6} lg={6} md={6} xl={6}>
            <TextField
              name="phone_numer"
              onChange={(e) => {
                setPhone(e.target.value);
                {
                  formData.phone_numer = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={phone}
              fullWidth
              label="Phone Number"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={6} lg={6} md={6} xl={6}>
            <TextField
              name="designation"
              onChange={(e) => {
                setDesignation(e.target.value);
                {
                  formData.designation = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={designation}
              fullWidth
              label="Designation"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={6} lg={6} md={6} xl={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
            sx={{mt:1}}
              name="date"
              label="Joining Date"
              onChange={(newTo_date) => {
                setDate(newTo_date);
                formData.date = moment(newTo_date).format("YYYY-MM-DD");
                handleFormDataChange;
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ margin: 1 }} fullWidth />
              )}
            />
          </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          Add Employee
        </Button>
      </Box>
    </>
  );
};

export default AddTeam_Member;
