import React from "react";
import ClippedDrawer from "../sidebar/sidebar_list";
import { useState, useEffect } from "react";
import { Grid, Typography, Card, Stack, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AnalyticEcommerce from "./design_component/analayticecom";
import MUIDataTable from "mui-datatables";
import Doughnut_Chart from "./design_component/doughnut_Chart";
import BasicTabs from "./dashboard_Tabs/MainTab";
import DoughnutChart from "./design_component/disc_chart";
import axios from "axios";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import logo from "../Logo/loader.png";
import TableSection from "./TableSectionCard/CardTabs";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import logos from "../Logo/loader.png";
import Loader from "../loader/loader";

const Dashbaordmain = () => {
  //const useState
  const navigate = useNavigate();
  const [dataIndex, setDataIndex] = useState(0);
  const [dashboard_Team, setDashboard_Team] = useState([]);
  const [dashboard_stats, setDashboard_Stats] = useState([]);
  const [member_name, setMember_name] = useState(null);
  const [datasets, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load_table_from_Card, setload_table_from_Card] = useState(false);
  const [top1, settop1] = useState();
  const [top2, settop2] = useState();
  const [top3, settop3] = useState();
  const [statsApi, setStatsApi] = useState([]);
  const [ChartData, setChartData] = useState([]);
  const [company_by, setCompanyBy] = useState([]);
  const [services_by, setServicesBy] = useState([]);
  const [emp_wise, setEmpWise] = useState([]);
  const [name_loader, setnameloader] = useState();
  const [logged_user_id, set_logged_user_id] = useState(
    localStorage.getItem("user_id")
  );
  console.log("logged_user_id", logged_user_id);
  // const function
  const user_id = localStorage.getItem("user_id");
  const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage
  const team_mates_name = dashboard_Team
    ?.filter((x) => x.user_id !== null)
    .map((x) => x.name)
    .sort();
  console.log("dashboard_Team?.map((x) => x.name)", dashboard_Team);
  console.log("membername", member_name);
  //code to get top

  const handleTop1 = (val) => {
    settop1(val);
  };
  console.log("top1val", top1);
  const handleTop2 = (val) => {
    settop2(val);
  };
  const handleTop3 = (val) => {
    settop3(val);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await axios(
        "https://api.startupfino.in/task-manager/api/task-management/"
      );
      setData(result.data.results);
      const team_mates = await axios(
        "https://api.startupfino.in/task-manager/api/team-mates/"
      );
      setDashboard_Team(team_mates.data);
      setLoading(false);
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (dashboard_Team?.length > 0) {
      const selectUser = dashboard_Team.filter((item) => {
        return item.user_id === parseInt(logged_user_id);
      });
      console.log("selectedUser", selectUser);
      if (selectUser.length > 0) {
        if (accessToken) {
          const instance = axios.create({
            baseURL: "https://api.startupfino.in", // API base URL
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (member_name) {
            instance
              .get(
                `/task-manager/task-stats/?task_assignee_id=${selectUser[0].id}`
              )
              .then((response) => {
                setStatsApi(response.data);
                toast.success(
                  `Showing Task Details of Employee - ${member_name}`
                );
              });
          }

          instance.get("/task-manager/task-chart-stats/").then((response) => {
            setChartData(response.data);
          });
          instance
            .get(`/task-manager/company_by_task_status/`)
            .then((response) => {
              setCompanyBy(response.data);
            });
          instance
            .get(`/task-manager/get_task_stats_by_service/`)
            .then((response) => {
              setServicesBy(response.data);
            });
          instance
            .get(`/task-manager/get_task_stats_by_employee/`)
            .then((response) => {
              setEmpWise(response.data);
              setLoading(false);
            });
        }
      }
      if (selectUser.length == 0 || user_id == 402) {
        console.log(selectUser, "selectUser");
        if (accessToken) {
          const instance = axios.create({
            baseURL: "https://api.startupfino.in", // API base URL
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          instance
            .get(`/task-manager/company_by_task_status/`)
            .then((response) => {
              setCompanyBy(response.data);
            });
          instance
            .get(`/task-manager/get_task_stats_by_service/`)
            .then((response) => {
              setServicesBy(response.data);
            });
          instance
            .get(`/task-manager/get_task_stats_by_employee/`)
            .then((response) => {
              setEmpWise(response.data);
            });
          instance.get("/task-manager/task-chart-stats/").then((response) => {
            setChartData(response.data);
            setLoading(false);
          });
        }
      }
      if (member_name === null) {
        console.log("success", member_name);
        const instance = axios.create({
          baseURL: "https://api.startupfino.in", // API base URL
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        instance.get(`/task-manager/task-stats/`).then((response) => {
          setStatsApi(response.data);
          toast.success(`Showing Company Task Details`);
        });
      }
    }
  }, [dashboard_Team, logged_user_id, member_name]);
  console.log("CompanyBy", company_by);
  let comp_task = datasets?.filter((x) => x.task_status.includes("Completed"));
  let pend_task = datasets?.filter((x) => !x.task_status.includes("Cancelled"));
  let pend_task2 = datasets?.filter(
    (x) =>
      !x.task_status.includes("Cancelled") &&
      !x.task_status.includes("Completed")
  );
  let client_pend = datasets?.filter((x) =>
    x.task_status.includes("Data is Pending from Client")
  );
  let delay_task = datasets?.filter(
    (x) =>
      !x.task_status.includes("Completed") &&
      !x.task_status.includes("Cancelled") &&
      moment(x.due_date).isBefore(moment(), "day") === false
  );
  let this_week = datasets?.filter(
    (x) =>
      !x.task_status.includes("Completed") &&
      !x.task_status.includes("Cancelled") &&
      moment(x.due_date).isSame(moment(), "week") === true
  );

  let last_month_pending = datasets?.filter(
    (x) =>
      !x.task_status.includes("Completed") &&
      !x.task_status.includes("Cancelled") &&
      moment(x.due_date).isSame(
        moment().subtract(1, "month").format("YYYY-MM-DD"),
        "month"
      ) === true
  );
  let Current_month = datasets?.filter(
    (x) =>
      x.task_status.includes("Completed") &&
      !x.task_status.includes("Cancelled") &&
      moment(x.due_date).isSame(moment().format("YYYY-MM-DD"), "month") === true
  );
  let bill_amount = 0;
  let paid_amount = 0;
  let unpaid_amount = 0;
  let counter = 0;
  let billabe_amount = datasets?.filter((x) => x.billing_info === "Billiable");
  let total_bill_amount = billabe_amount?.map(
    (x) => (bill_amount = bill_amount + x.billing_amount)
  );
  let paid_billable = billabe_amount?.filter((x) => x.invoice_num !== null);
  let total_paid_amount = paid_billable?.map(
    (x) => (paid_amount = paid_amount + x.billing_amount)
  );
  let unpaid_billable = billabe_amount?.filter((x) => x.invoice_num === null);
  let total_unpaid_amount = unpaid_billable?.map(
    (x) => (unpaid_amount = unpaid_amount + x.billing_amount)
  );
  let todays_due_Date = datasets?.filter(
    (x) =>
      moment(x.due_date).isSame(moment(), "day") === true &&
      !x.task_status.includes("Completed") &&
      !x.task_status.includes("Cancelled")
  );
  let todays_created = datasets?.filter(
    (x) => moment(x.created_at).isSame(moment(), "day") === true
  );
  let todays_completed = datasets?.filter(
    (x) =>
      moment(x.modified_at).isSame(moment(), "day") === true &&
      x.task_status.includes("Completed")
  );

  const handleClickedcard = (e, val) => {
    setload_table_from_Card(false);
    setDataIndex(val);
    console.log("cardisclicked", val);
  };
  console.log("dataIndex", dataIndex);
  const handleToTaskView = (e, val) => {
    navigate("/task_view");
    setDataIndex(val);
  };

  return (
    <>
      <Box
        sx={{ display: "flex", backgroundColor: loading ? "white" : "#e1e2e3" }}
      >
        <ClippedDrawer />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pr: 3,
                pt: 2,
                pl: 2,
                width: "100%",
                overflowX: "auto",
              }}
            >
              <Grid container>
                {/* Employee Selection */}
                    <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                      <Card sx={{ p: 1.25 }}>
                        <Autocomplete
                          name="task_company_name"
                          value={member_name}
                          fullWidth
                          onChange={(e, newVal) => {
                            setMember_name(newVal);
                            const name = newVal;
                            const user_id_Filter = dashboard_Team?.filter(
                              (item) => item.name == name
                            );
                            if (user_id_Filter) {
                              setnameloader(true);
                              setTimeout(function () {
                                setnameloader(false);
                              }, 2000);
                            }
                            console.log(
                              "user_id_Filter",
                              user_id_Filter,
                              "ee",
                              newVal
                            );
                            set_logged_user_id(user_id_Filter[0]?.user_id);
                          }}
                          options={team_mates_name}
                          renderInput={(params) => (
                            <TextField {...params} label="Employee Name" />
                          )}
                        />
                      </Card>
                    </Grid>
              </Grid>
              {/* {card 1st row} */}

              <br></br>
              {name_loader ? (
                <>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ minHeight: "70vh", position: "relative" }}
                  >
                    <Grid item sx={{ position: "absolute", zIndex: 2 }}>
                      <CircularProgress color="info" size={90} />
                    </Grid>
                    <Grid item sx={{ position: "absolute", zIndex: 1 }}>
                      <img src={logos} alt="Logo" />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container rowSpacing={4.5} columnSpacing={1.75}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Card
                        contentSX={{ p: 2.25, backgroundcolor: "lightblue" }}
                        onClick={(e) => {
                          handleClickedcard(e, 0);
                        }}
                      >
                        <Stack spacing={0.5}>
                          <Grid
                            item
                            alignItems="center"
                            sx={{
                              backgroundColor: "orange",
                              borderTopLeftRadius: "5px",
                              borderTopRightRadius: "5px",
                            }}
                          >
                            <Typography fontSize="20px" sx={{ m: 0.5 }}>
                              Total Task
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center">
                            <Grid item alignItems="center">
                              <Typography variant="h4" color="" sx={{ p: 1 }}>
                                {console.log("StatsApi", statsApi)}
                                {statsApi?.total_task}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Stack>
                        <Box sx={{ pt: 2.25 }}></Box>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 1);
                      }}
                    >
                      <AnalyticEcommerce
                        title="Completed Task"
                        count={statsApi.completed_task}
                        percentage={70.5}
                        extra="8,900"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 2);
                      }}
                    >
                      <AnalyticEcommerce
                        title="Pending Task"
                        count={statsApi?.total_task - statsApi?.completed_task}
                        percentage={27.4}
                        isLoss
                        extra="1,943"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 3);
                      }}
                    >
                      <AnalyticEcommerce
                        title="Data is Pending by Client"
                        count={statsApi.data_pending_task}
                        percentage={27.4}
                        isLoss
                        color="warning"
                        extra="$20,395"
                      />
                    </Grid>
                  </Grid>
                  {/* {2nd row} */}
                  <Grid container rowSpacing={4.5} columnSpacing={1.75}>
                    <Grid item xs={12} sx={{ mb: -2.25 }}>
                      <Typography variant="h5"></Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 4);
                      }}
                    >
                      <AnalyticEcommerce
                        title="Delayed Task"
                        count={statsApi.delayed_task}
                        percentage={59.3}
                        extra="35,000"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 5);
                      }}
                    >
                      <AnalyticEcommerce
                        title="Current Week Task"
                        count={statsApi.current_week_task}
                        percentage={70.5}
                        extra="8,900"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 6);
                      }}
                    >
                      <AnalyticEcommerce
                        title={
                          moment().subtract(1, "month").format("MMM") +
                          " Pending Task"
                        }
                        count={statsApi.last_month_task}
                        percentage={27.4}
                        isLoss
                        color="warning"
                        extra="1,943"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      onClick={(e) => {
                        handleClickedcard(e, 7);
                      }}
                    >
                      <AnalyticEcommerce
                        title={moment().format("MMM") + " Completed Task"}
                        count={statsApi?.current_month_completed_task}
                        percentage={27.4}
                        isLoss
                        color="warning"
                        extra="20,395"
                      />
                    </Grid>
                  </Grid>
                  {/* {3rd row} */}
                  <Grid container rowSpacing={4.5} columnSpacing={1.75}>
                    {/* <IFrameWrapper title="Ant Icon" width="100%" src="https://ant.design/components/icon/" /> */}
                    <Grid item xs={12} sx={{ mb: -2.25 }}>
                      <Typography variant="h5"></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <AnalyticEcommerce
                        title="Billable Amount"
                        count={`₹${statsApi.billing_amount}`}
                        percentage={59.3}
                        extra="35,000"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <AnalyticEcommerce
                        title="Paid"
                        count={`(${statsApi?.paid_billiable_task})`}
                        percentage={70.5}
                        extra="8,900"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <AnalyticEcommerce
                        title="Unpaid"
                        count={`(${statsApi?.unpaid_billiable_task})`}
                        percentage={27.4}
                        isLoss
                        extra="1,943"
                        a
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <AnalyticEcommerce
                        title="Due Today"
                        count={todays_due_Date?.length}
                        percentage={27.4}
                        isLoss
                        color="warning"
                        extra="$20,395"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <AnalyticEcommerce
                        title="Created Today"
                        count={statsApi?.created_today_task}
                        percentage={27.4}
                        isLoss
                        color="warning"
                        extra="$20,395"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <AnalyticEcommerce
                        title="Done Today"
                        count={statsApi?.completed_today_task}
                        percentage={27.4}
                        isLoss
                        color="warning"
                        extra="$20,395"
                      />
                    </Grid>
                  </Grid>
                  {/* {Charts Sections} */}
                  <Grid
                    container
                    alignItems="center"
                    justifyItems="center"
                    spacing={2}
                  >
                    <Grid item xs={6} sm={6} md={6} lg={6} sx={{ mt: 3 }}>
                      <Card>
                        <Doughnut_Chart
                          datasets={ChartData?.last_six_months_completed_task}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} sx={{ mt: 3 }}>
                      <Card sx={{ p: 1 }}>
                        <DoughnutChart
                          datasets={emp_wise}
                          comp_task={statsApi.completed_task}
                          pend_task={
                            statsApi?.total_task - statsApi.completed_task
                          }
                          delay_task={statsApi?.delayed_task}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* {tabs below} */}
              <Grid sx={{ mt: 5 }}>
                <Card>
                  <BasicTabs
                    datasets={datasets}
                    company_wise={company_by}
                    emp_wise={emp_wise}
                    services_wise={services_by}
                    handleTop1={handleTop1}
                    handleTop2={handleTop2}
                    handleTop3={handleTop2}
                  />
                </Card>
              </Grid>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Dashbaordmain;
