import React from "react";
import { useState, useEffect } from "react";
import axios, { all } from "axios";
import { Box, Grid, Button, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ToastContainer, toast } from "react-toastify";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
const Taskupdate = (props) => {
  const {
    row,
    company_name, //mapped value to be use in Autocomplete options
    task_names, //mapped value to be use in Autocomplete options
    teamates_data, //mapped value to be use in Autocomplete options
    client_mail_list, //mapped value to be use in Autocomplete options
    all_companies, //all companies list
    all_task_name, //all task name list
    all_team_members, //all team members list
    all_created_Task, //all created task list
    handleUpdate,
    handleClosee,
  } = props;

  const main_task = all_created_Task?.filter((item) => item.id === row)[0];
  const selected_task = main_task;
  console.log("selectedTask", selected_task);
  const [formData, setFormData] = useState({
    company_name: "",
    task_name: "",
    task_description: "",
    task_owner: "",
    task_assignee: "",
    due_date: "",
    billing_info: "",
    task_status: "",
    remark: "",
    billing_amount: "",
    task_assignee_id: "",
    task_owner_id: "",
    invoice_num: "",
    attachment: "",
  });
  const [Cname, setCname] = useState(null);
  const [taskName, setTakeName] = useState(null);
  const [Tdes, setTdes] = useState(null);
  const [Binfo, setBinfo] = useState(null);
  const [Tstat, setTstat] = useState(null);
  const [remark, setRemark] = useState(undefined);
  const [Bamount, setBamount] = useState(null);
  const [Town, setTown] = useState(null);
  const [Tassign, setTassign] = useState(null);
  const [dueDate, setDueDate] = useState("");
  const [invoiceId, setInvoiceId] = useState(null);
  const [attachment, setAttachment] = useState(null);
  useEffect(() => {
    setCname(selected_task?.company_name);
    setTakeName(selected_task.task_name);
    setTdes(selected_task.task_description);
    setBinfo(selected_task.billing_info);
    setTstat(selected_task.task_status);
    setRemark(selected_task.remark);
    setBamount(selected_task.billing_amount);
    setTown(selected_task.task_owner);
    setTassign(selected_task.task_assignee);
    setDueDate(selected_task.due_date);
    setInvoiceId(selected_task.invoice_num);
    setAttachment(selected_task.attachment);
  }, [main_task]);
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dis = (e) => {
    var bodyFormData = new FormData();
    const company_ids = all_companies.filter(
      (item) => item.company_name === formData['task_company_name']
    );
    console.log("company_ids", company_ids);
    if (company_ids.length > 0) {
      formData['company_id'] = company_ids[0]['id'];
    }
    for (let key in formData) {
      if ((key, formData[key] !== "")) {
        if (key === "due_date") {
          bodyFormData.append(key, moment(formData[key]).format("YYYY-MM-DD"));
        } else {
          if (key === "task_owner") {
            const task_owner_id = all_team_members.filter(
              (item) => item.name === formData[key]
            );
            const objName = "task_owner_id";
            bodyFormData.append(objName, task_owner_id[0].id);
          } else if (key === "task_assignee") {
            const task_assignee_id = all_team_members.filter(
              (item) => item.name === formData[key]
            );
            const objName = "task_assignee_id";
            bodyFormData.append(objName, task_assignee_id[0].id);
          } else if (key === "task_name") {
            const task_name_id = all_task_name.filter(
              (item) => item.task_name === formData[key]
            );
            const objName = "task_id";
            bodyFormData.append(objName, task_name_id[0].id);
          }
          bodyFormData.append(key, formData[key]);
        }

      }
    }
    // dispatch(updatetask(selected_task.sr_id, bodyFormData));
    const apiUrl = `https://api.startupfino.in/task-manager/api/task-management/${selected_task.sr_id}`;
    axios
      .patch(apiUrl, bodyFormData)
      .then((response) => {
        console.log("Task Updated successfully:", response.data);
        handleClosee();
        handleUpdate();
        toast.success("Task Updated Successfully");
      })
      .catch((error) => {
        console.error("Error adding task:", error);
      });
    console.log("bodyFormData", formData);
  };
  const handleSelectFile = (e) => {
    e.preventDefault();
    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log("File name", formData);
  };
  return (
    <>
      <Box
        sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
        display="flex"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Cname || ""}
              name="company_name"
              onChange={(event, newValue) => {
                setCname(newValue);
                {
                  formData.company_name = newValue;

                }
                {
                  handleFormDataChange;
                }
              }}
              options={company_name}
              sx={{ margin: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Company Name" />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={taskName}
              name="task_name"
              freeSolo
              onInputChange={(event, newValue) => {
                setTakeName(newValue);
                {
                  formData.task_name = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={task_names}
              sx={{ margin: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Task Name" />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              value={Tdes}
              name="task_description"
              InputLabelProps={{ shrink: true }}
              width={"250px"}
              onChange={(e) => {
                setTdes(e.target.value);
                {
                  formData.task_description = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              label="Task Description"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Town}
              name="task_owner"
              onChange={(event, newValue) => {
                setTown(newValue);
                {
                  formData.task_owner = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={teamates_data}
              sx={{ margin: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Task Owner" />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tassign}
              name="task_assignee"
              onChange={(event, newValue) => {
                setTassign(newValue);
                {
                  formData.task_assignee = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={teamates_data}
              sx={{ margin: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Task Assigne" />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6} sx={{ mt: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                name="due_date"
                label="Due Date"
                value={moment.parseZone(dueDate)}
                onChange={(newTo_date) => {
                  setDueDate(newTo_date);
                  formData.due_date = moment(newTo_date).format("YYYY-MM-DD");
                  handleFormDataChange;
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ margin: 1 }} fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Binfo}
              name="billing_info"
              onChange={(event, newValue) => {
                setBinfo(newValue);
                {
                  formData.billing_info = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={["Billiable", "Non Billiable", "Reimbursement"]}
              sx={{ margin: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Billing Info" />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="billing_amount"
              onChange={(e) => {
                setBamount(e.target.value);
                {
                  formData.billing_amount = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Bamount}
              label="Biling Amount"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <Autocomplete
              value={Tstat}
              name="task_status"
              onChange={(event, newValue) => {
                setTstat(newValue);
                {
                  formData.task_status = newValue;
                }
                {
                  handleFormDataChange;
                }
              }}
              id="controllable-states-demo"
              options={[
                "Completed",
                "Initiated",
                "In the Process",
                "Data is Pending from Client",
                "Not Started",
                "Cancelled",
              ]}
              sx={{ margin: 1 }}
              renderInput={(params) => (
                <TextField {...params} label="Status of Task" />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6} md={6} xl={6}>
            <TextField
              name="invoice_num"
              onChange={(e) => {
                setInvoiceId(e.target.value);
                {
                  formData.invoice_num = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={invoiceId}
              label="Invoice ID"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="remark"
              onChange={(e) => {
                setRemark(e.target.value);
                {
                  formData.remark = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              fullWidth
              value={remark}
              label="Remarks"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
          {console.log("formData",formData)}
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="attachment"
              onChange={handleSelectFile}
              fullWidth
              type="file"
              hidden
              sx={{ mt: 1 }}
              helperText={
                attachment !== "" ? (
                  <>
                    View File
                    <IconButton
                      onClick={() => {
                        window.open(selected_task.attachment);
                      }}
                      aria-label="delete"
                      color="primary"
                    >
                      <AttachFileIcon />
                    </IconButton>
                    Delete File
                    <IconButton
                      onClick={(e)=>{
                        formData.attachment=null
                        setAttachment("")
                      }}
                      aria-label="delete"
                      color="error"
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </>
                ) : (
                  "No file"
                )
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button
          onClick={dis}
          variant="contained"
          sx={{ backgroundcolor: "#229A16" }}
        >
          Update Task
        </Button>
      </Box>
    </>
  );
};
export default Taskupdate;
