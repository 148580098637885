import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Grid, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { toast } from "react-toastify";
import { Chip } from "@mui/material";

const Task_Add = (props) => {
  const {
    handleClosee,
    company_name,
    task_names,
    teamates_data,
    client_mail_list,
    all_companies,
    all_mail,
    all_task_name,
    all_team_members,
    handleUpdate,
  } = props;
  const [task_company_name, setTask_company_name] = useState();
  const [task_name, setTask_name] = useState();
  const [task_description, setTask_description] = useState();
  const [task_owner, setTask_owner] = useState();
  const [task_assignee, setTask_assignee] = useState();
  const [due_date, setDue_date] = useState(null);
  const [billing_info, setBilling_type] = useState();
  const [billing_amount, setBilling_amount] = useState();
  const [task_status, setTask_status] = useState();
  const [remarks, setRemarks] = useState();
  const [client_mail, setClient_mail] = useState([]);

  const [formData, setFormData] = useState({
    task_company_name: "",
    task_name: "",
    task_description: "",
    task_owner: "",
    task_assignee: "",
    due_date: "",
    billing_info: "",
    billing_amount: "",
    task_status: "",
    remark: "",
    client_mail: "",
    attachment: "",
    client_mails: "",
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    console.log("called", name, valuee);
    setFormData({ ...formData, [name]: valuee });
  };
  const [tags, setTags] = useState([]);

  const handleChangeMail = (event, newTags) => {
    setTags(newTags);
  };
  console.log(tags, "tags");
  const handleSelectFile = (e) => {
    e.preventDefault();
    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log("File name", formData);
  };
  console.log(
    "rushbah",
    all_companies.filter(
      (item) => item?.company_name === formData["task_company_name"]
    )
  );
  console.log("formData", formData);
  const handleAddTask = () => {
    const company_ids = all_companies.filter(
      (item) => item.company_name === formData['task_company_name']
    );
    console.log("company_ids", company_ids);
    if (company_ids.length > 0) {
      formData['company_id'] = company_ids[0]['id'];
    }
    // formData["company_id"] = all_companies.filter(
    //   (item) => item?.company_name === formData["task_company_name"]
    // )[0]["id"];
    // formData["company_name"] = formData["task_company_name"];

    if (formData.client_mail !== "") {
    const mail_list_last_item =formData?.client_mail[formData?.client_mail?.length - 1];
      formData.client_mail = mail_list_last_item?.join(",");
    }

    formData.due_date = moment(formData?.due_date).format("YYYY-MM-DD");

    let temp_name = formData.task_name;
    const opts = all_task_name?.filter((x) => x.task_name.includes(temp_name));
    console.log("opts", opts, all_task_name);
    formData["task_id"] = opts[0]?.id;

    let temp_ass = formData?.task_assignee;
    const opts_ass = all_team_members?.filter((x) => x.name.includes(temp_ass));
    console.log("opts_ass", opts_ass);
    formData["task_assignee_id"] = opts_ass[0]?.id;
    let temp_own = formData?.task_owner;
    const opts_own = all_team_members?.filter((x) => x.name.includes(temp_own));
    console.log("opts_own", opts_own);
    formData["task_owner_id"] = opts_own[0]?.id;
    var bodyFormData = new FormData();
    formData["company_name"];
    for (let key in formData) {
      if(key==="client_mails"){
        formData.client_mails=tags.length===0?null:tags.join(",")
        bodyFormData.append(key,tags.length===0?null:tags.join(","))
        console.log("client_mails",tags.length===0?null:tags.join(","))
      }
      if(key==="task_company_name"){
        bodyFormData.append("company_name",formData.task_company_name)
      }
      if ((key, formData[key] !== "")) {
        bodyFormData.append(key, formData[key]);
      }
    }
    const apiUrl =
      "https://api.startupfino.in/task-manager/api/task-management/";
    axios
      .post(apiUrl, bodyFormData)
      .then((response) => {
        console.log("Task added successfully:", response.data);
        handleClosee();
        handleUpdate();
        toast.success("Task Added Successfully");
      })
      .catch((error) => {
        // Handle errors that occur during the request
        console.error("Error adding task:", error);
        toast.error("Task Addition Failed Check Required Fields");

      });
  };

  return (
    <Box
      sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
      display="flex"
    >
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Autocomplete
            name="task_company_name"
            id="controllable-states-demo"
            value={task_company_name}
            freeSolo
            onInputChange={(event, newValue) => {
              setTask_company_name(newValue);
              {
                formData.task_company_name = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            options={company_name}
            renderInput={(params) => (
              <TextField {...params} label="Company Name" required/>
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Autocomplete
            name="task_name"
            disablePortal
            id="combo-box-demo"
            value={task_name}
            onChange={(event, newValue) => {
              setTask_name(newValue);
              {
                formData.task_name = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            options={task_names}
            renderInput={(params) => (
              <TextField {...params} label="Task Name" required/>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <TextField
            name="task_description"
            id="controllable-states-demo"
            required
            value={task_description}
            onChange={(event, newValue) => {
              setTask_description(event.target.value);
              {
                formData.task_description = event.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="Task Description"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Autocomplete
            name="task_owner"
            id="combo-box-demo"
            value={task_owner}
            onChange={(event, newValue) => {
              setTask_owner(newValue);
              {
                formData.task_owner = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            options={teamates_data}
            renderInput={(params) => (
              <TextField {...params} label="Task Owner" required/>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Autocomplete
            name="task_assignee"
            id="combo-box-demo"
            value={task_assignee}
            onChange={(event, newValue) => {
              setTask_assignee(newValue);
              {
                formData.task_assignee = newValue;
              } 
              {
                handleFormDataChange;
              }
            }}
            options={teamates_data}
            renderInput={(params) => (
              <TextField {...params} label="Task Assignee" required />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              name="due_date"
              label="Due Date *"
              format="DD/MM/YYYY"
              required
              onChange={(newTo_date) => {
                setDue_date(newTo_date);
                formData.due_date = moment(newTo_date).format("YYYY-MM-DD");
                handleFormDataChange;
              }}
              renderInput={(params) => (
                <TextField {...params} sx={{ margin: 1 }} fullWidth required/>
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Autocomplete
            name="billing_info"
            id="controllable-states-demo"
            value={billing_info}
            onChange={(event, newValue) => {
              setBilling_type(newValue);
              {
                formData.billing_info = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            options={["Billiable", "Non Billiable", "Reimbursement"]}
            renderInput={(params) => (
              <TextField {...params} label="Billing Type" />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <TextField
            type="number"
            name="billing_amount"
            id="controllable-states-demo"
            value={billing_amount}
            onChange={(event, newValue) => {
              setBilling_amount(event.target.value);
              {
                formData.billing_amount = event.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="Billing Amount"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <Autocomplete
            name="task_status"
            id="controllable-states-demo"
            value={task_status}
            onChange={(event, newValue) => {
              setTask_status(newValue);
              {
                formData.task_status = newValue;
              }
              {
                handleFormDataChange;
              }
            }}
            options={[
              "Completed",
              "Initiated",
              "In the Process",
              "Data is Pending from Client",
              "Not Started",
              "Cancelled",
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Status Of Task" />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6} xl={6}>
          <TextField
            name="remarks"
            id="controllable-states-demo"
            value={remarks}
            onChange={(event, newValue) => {
              setRemarks(event.target.value);
              {
                formData.remark = event.target.value;
              }
              {
                handleFormDataChange;
              }
            }}
            label="Remarks"
            fullWidth
          />
        </Grid>
        <TextField
          name="attachment"
          onChange={handleSelectFile}
          type="file"
          hidden
          sx={{ mt: 1 }}
          helperText={
            formData.attachment !== "" ? formData.attachment.name : "No file"
          }
        />
      </Grid>
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={12} md={12} xl={12}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={client_mail_list}
            value={tags}
            freeSolo
            onChange={handleChangeMail}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Client Mail"
                value={tags.join(",")}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button variant="contained" onClick={handleAddTask}>
          Add Task
        </Button>
      </Box>
    </Box>
  );
};

export default Task_Add;
