import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import BadgeIcon from "@mui/icons-material/Badge";
import TaskIcon from "@mui/icons-material/Task";
import logo from "../Logo/startupfino.png";
import logo2 from "../Logo/icon.png";
import { useNavigate,useSearchParams } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import RepeatIcon from "@mui/icons-material/Repeat";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import BusinessIcon from "@mui/icons-material/Business";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { useState,useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import TuneIcon from '@mui/icons-material/Tune';
import moment from "moment";
export default function ClippedDrawer() {
  const [url_val, seturl_val] = useSearchParams();
  const [drawerWidth, setdrawerwidth] = useState(220);
  const category = url_val.get("category");
  
  useEffect(()=>{
    setopen(true)
  },[category])
  const [open, setopen] = useState(false);
  const navigate = useNavigate();
  let abc = localStorage.getItem("api_token");
  let xyz= localStorage.getItem("loginTime")
  var isAuthenticated = Boolean(abc);
  if(xyz){
   var a = moment(xyz)
   var b = moment()
   if(b.diff(a,"hours")>8){
    navigate("/signin")
   }
   console.log("LoggednInSince",b.diff(a,"minutes"))
  }

  const dividerStyle = {
    backgroundColor: "white",
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: open === true ? "80px" : "250px",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: open === true ? "80px" : "250px",
            boxSizing: "border-box",
            backgroundColor: "black",
          },
        }}
      >
        {open === false ? (
          <img src={logo} alt="Logo" />
        ) : (
          <>
          <br></br>
          <img src={logo2} alt="Logo"/>
          <br></br>
          </>
        )}
        {open == false && (
          <Box sx={{ overflow: "auto" }} container>
            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white",marginRight: "-10px" }}>
                  <DashboardIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>
            <Divider style={dividerStyle} />

            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/company-list");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white",marginRight: "-10px" }}>
                  <BusinessIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Company List" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/track-compliance");
                setopen(true)
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-9px" }}>
                  <TroubleshootIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Track Compliance" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/notification-compliance");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white",marginRight: "-10px" }}>
                  <NotificationsActiveIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Notify Compliance" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/preference");
                setopen(true)
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-9px" }}>
                  <TuneIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Preference" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />


            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/task_view");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white",marginRight: "-10px" }}>
                  <ReceiptLongIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Task Creation" />
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/task_name");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px"}}>
                  <TaskIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Task Name" />
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/team_name");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px"}}>
                  <BadgeIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Team Name" />
              </ListItemButton>
            </ListItem>
            <Divider style={dividerStyle} />

            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/recurring_tasks");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px"}}>
                  <RepeatIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Recurring Task" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/notification");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px"}}>
                  <NotificationsActiveIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Notify Client" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />

            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                localStorage.removeItem("api_token");
                localStorage.removeItem("user_id");
                navigate("/signin");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", }}>
                  <LogoutIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => setopen(!open)}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", }}>
                  <ArrowForwardIosIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText primary="Collapse" />
              </ListItemButton>
            </ListItem>
          </Box>
        )}
        {open == true && (
          <Box sx={{ overflow: "auto" }} container>
            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <DashboardIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Dashboard" /> */}
              </ListItemButton>
            </ListItem>
            <Divider style={dividerStyle} />

            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/company-list");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <BusinessIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Company List" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                setopen(true)
                navigate("/track-compliance");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-9px" }}>
                  <TroubleshootIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Track Compliance" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/notification-compliance");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <NotificationsActiveIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Notify Compliance" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/preference");
                setopen(true)
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-9px" }}>
                  <TuneIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Preference" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />


            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/task_view");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <ReceiptLongIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Task Creation" /> */}
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/task_name");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <TaskIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Task Name" /> */}
              </ListItemButton>
            </ListItem>
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/team_name");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <BadgeIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Team Name" /> */}
              </ListItemButton>
            </ListItem>
            <Divider style={dividerStyle} />

            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/recurring_tasks");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <RepeatIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Recurring Task" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                navigate("/notification");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <NotificationsActiveIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Notify Client" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />

            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => {
                localStorage.removeItem("api_token");
                localStorage.removeItem("user_id");
                localStorage.setItem("loginTime",null)
                navigate("/signin");
              }}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <LogoutIcon style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Logout" /> */}
              </ListItemButton>
            </ListItem>

            <Divider style={dividerStyle} />
            <ListItem
              sx={{ color: "white" }}
              disablePadding
              onClick={() => setopen(!open)}
            >
              <ListItemButton>
                <ListItemIcon style={{ color: "white", marginRight: "-10px" }}>
                  <ArrowForwardIos style={{ color: "white" }} />
                </ListItemIcon>
                {/* <ListItemText primary="Collapse" /> */}
              </ListItemButton>
            </ListItem>
          </Box>
        )}


      </Drawer>
    </Box>
  );
}
