import PropTypes from "prop-types";

// material-ui
import { Box, Chip, Grid, Stack, Typography, Card } from "@mui/material";

// project import

// assets
import { RiseOutlined, FallOutlined } from "@ant-design/icons";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticEcommerce = ({
  color,
  title,
  count,
  percentage,
  isLoss,
  extra,
}) => (
  <Card contentSX={{ p: 2.25, backgroundcolor: "lightblue" }}>
    <Stack spacing={0.5}>
      <Grid item alignItems="center" sx={{backgroundColor:'orange',borderTopLeftRadius:'5px',borderTopRightRadius:'5px'}}>
            <Typography fontSize="20px" sx={{m:0.5}}>{title}</Typography>
      </Grid>
      <Grid container alignItems="center">
        <Grid item alignItems="center">
          <Typography variant={title==="Billable Amount" ||title==="Paid" ||title==="Unpaid" ||title==="Due Today"||title==="Created Today"||title==="Done Today"?"p":"h4"} color="" 
          sx={{ p: 1 }}
          >
            {count}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
    <Box sx={{ pt: 2.25 }}></Box>
  </Card>
);

AnalyticEcommerce.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

AnalyticEcommerce.defaultProps = {
  color: "primary",
};

export default AnalyticEcommerce;
