import React from "react";
import { useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
const Addtask_name = (props) => {
  const { handleClosee,handleUpdate } = props;
  const [formData, setFormData] = useState({
    task_name: ''
  });
  const [Fname, setFname] = useState();
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };

  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    const apiUrl =
    "https://api.startupfino.in/task-manager/api/task-name/";
  axios
    .post(apiUrl, bodyFormData)
    .then((response) => {
      console.log("Task added successfully:", response.data);
      handleClosee();
      handleUpdate();
      toast.success('Task Name Added Successfully')
    })
    .catch((error) => {
      // Handle errors that occur during the request
      console.error("Error adding task:", error);
    });
    handleClosee();
  };
 
  return (
    <>
      <Box
        sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
        display="flex"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="task_name"
              onChange={(e) => {
                setFname(e.target.value);
                {
                  formData.task_name = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={Fname}
              fullWidth
              label="Task Name"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          Add Task Name
        </Button>
      </Box>
    </>
  );
};

export default Addtask_name;
