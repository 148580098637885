import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CssBaseline, Grid } from "@mui/material";
import ClippedDrawer from "../sidebar/sidebar_list";
import Onboard_Company from "./Onboard_Company";
import Task_Company from "./Task_Company";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Company_tabs() {
  const [value, setValue] = React.useState(0);
  const [loader,setloader]=React.useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ClippedDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pr: 3,
          pt: 2,
          pl: 2,
          width: "100%",
          overflowX: "auto",
        }}
      >
        {" "}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Task Company" {...a11yProps(0)} />
          <Tab label="Onboard Company" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <Task_Company />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Onboard_Company />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
