import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Grid, Button, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { parseZone } from "moment";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Chip } from "@mui/material";

const Edit_Recurring_Data = (props) => {
  const {
    row,
    company_name, //mapped value to be use in Autocomplete options
    task_names, //mapped value to be use in Autocomplete options
    teamates_data, //mapped value to be use in Autocomplete options
    client_mail_list, //mapped value to be use in Autocomplete options
    all_companies, //all companies list
    all_task_name, //all task name list
    all_team_members, //all team members list
    all_created_Task, //all created task list
    handleUpdate,
    handleClosee,
  } = props;
  const main_task = all_created_Task?.filter((item) => item.id === row)[0];
  const selected_task = main_task;
  const [task_company_name, setTask_company_name] = useState("");
  const [task_name, setTask_name] = useState("");
  const [task_description, setTask_description] = useState();
  const [task_owner, setTask_owner] = useState("");
  const [task_assignee, setTask_assignee] = useState("");
  const [due_date, setDue_date] = useState(null);
  const [billing_info, setBilling_type] = useState("");
  const [billing_amount, setBilling_amount] = useState("");
  const [task_status, setTask_status] = useState("");
  const [remarks, setRemarks] = useState();
  const [recurrence_rule_to_Set, setrecurrence_rule_to_Set] = useState(" ");
  const [want_To_Create, setwant_To_Create] = useState();
  const [custom_due_date, setcustom_due_date] = useState(null);
  const [next_due_date, setNext_due_date] = useState(null);
  const [Last_due_date, setLast_due_date] = useState(null);
  const [q1_month, setq1_month] = useState(3);
  const [q2_month, setq2_month] = useState(6);
  const [q3_month, setq3_month] = useState(9);
  const [q4_month, setq4_month] = useState(0);
  const [half_cy1, sethalf_cy1] = useState(0);
  const [half_cy2, sethalf_cy2] = useState(6);
  const [half_fy1, sethalf_fy1] = useState(3);
  const [half_fy2, sethalf_fy2] = useState(9);
  const [annually_cy, setannually_cy] = useState(0);
  const [annually_fy, setannually_fy] = useState(3);
  const [client_mail, setClient_mail] = useState([]);
  const [recurrence_rule, setrecurrence_rule] = useState("");
  const [recurrence_weekly, setrecurrence_weekly] = useState();
  const [nextbtn, setNextbtn] = useState(false);
  const [recurrence_monlthy, setrecurrence_monlthy] = useState();
  const [attachment, setAttachment] = useState("");
  console.log("selected_task", selected_task);
  useEffect(() => {
    setTask_company_name(selected_task?.company_name);
    setTask_name(selected_task?.task_name);
    setTask_description(selected_task?.task_description);
    setTask_owner(selected_task?.task_owner);
    setTask_assignee(selected_task?.task_assignee);
    setDue_date(selected_task?.due_date);
    setBilling_type(selected_task?.billing_info);
    setBilling_amount(selected_task?.billing_amount);
    setTask_status(selected_task?.task_status);
    setRemarks(selected_task?.remarks);
    setrecurrence_rule(selected_task?.recurrence_rule);
    setClient_mail(selected_task?.client_mails);
    setAttachment(selected_task?.attachment);
    setrecurrence_rule_to_Set(selected_task?.recurring_period);
  }, [main_task]);
  console.log("reccurence_rule", recurrence_rule);
  console.log("client_mailing", client_mail);

  const handleNext = () => {
    setNextbtn(true);
  };
  const handlePrev = () => {
    setNextbtn(false);
  };
  const [tags, setTags] = useState([]);

  const handleChangeMail = (event, newTags) => {
    setTags(newTags);
  };
  console.log(tags, "tags");
  const handleSelectFile = (e) => {
    e.preventDefault();
    name = e.target.name;
    valuee = e.target.files[0];
    setFormData({ ...formData, [name]: valuee });
    console.log("File name", formData);
  };

  const [formData, setFormData] = useState({
    task_company_name: "",
    task_name: "",
    task_description: "",
    task_owner: "",
    task_assignee: "",
    due_date: "",
    billing_info: "",
    billing_amount: "",
    task_status: "",
    remarks: "",
    client_mails: "",
    recurrence_rule: "",
    want_To_Create: "",
    custom_due_date: "",
    next_due_date: "",
    Last_due_date: "",
    recurrence_monlthy: "",
    attachment: "",
    recurring_period: "",
  });
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    console.log("called", name, valuee);
    setFormData({ ...formData, [name]: valuee });
  };
  console.log(
    "rushbah",
    all_companies.filter(
      (item) => item?.company_name === formData["task_company_name"]
    )
  );
  console.log("formData", formData);
  const handleAddTask = () => {
    formData["company_id"] = all_companies.filter(
      (item) => item?.company_name === formData["task_company_name"]
    )[0]["id"];
    formData["company_name"] = formData["task_company_name"];

    const mail_list_last_item =
      //   formData?.client_mail[formData?.client_mail?.length - 1];
      // console.log(mail_list_last_item);
      // formData.client_mail = mail_list_last_item?.join(",");
      // formData["client_mails"] = formData["client_mail"];
      console.log("Fomr", tags?.join(","));
    formData.due_date = moment(formData?.due_date).format("YYYY-MM-DD");

    let temp_name = formData.task_name;
    const opts = all_task_name?.filter((x) => x.task_name.includes(temp_name));
    console.log("opts", opts, all_task_name);
    formData["task_id"] = opts[0]?.id;

    let temp_ass = formData?.task_assignee;
    const opts_ass = all_team_members?.filter((x) => x.name.includes(temp_ass));
    console.log("opts_ass", opts_ass);
    formData["task_assignee_id"] = opts_ass[0]?.id;
    let temp_own = formData?.task_owner;
    const opts_own = all_team_members?.filter((x) => x.name.includes(temp_own));
    console.log("opts_own", opts_own);
    formData["task_owner_id"] = opts_own[0]?.id;
    var bodyFormData = new FormData();
    bodyFormData.append("is_recurring", true);
    const by_day = moment(formData.due_date).format("YYYY-MM-DD HH:mm:ss");
    console.log(
      "by_day",
      moment(by_day).format("dddd").substring(0, 2).toLocaleUpperCase()
    );
    // bodyFormData.append("BYDAY",moment(by_day).format('dddd').substring(0,2).toLocaleUpperCase())
    formData.next_due_date = moment(due_date).format("YYYY-MM-DD");
    formData.Last_due_date = moment(due_date).format("YYYY-MM-DD");

    bodyFormData.append("next_due_date", moment(due_date).format("YYYY-MM-DD"));
    bodyFormData.append("Last_due_date", moment(due_date).format("YYYY-MM-DD"));

    for (let key in formData) {
      if (key === "recurrence_rule") {
        if (recurrence_rule_to_Set === "Monthly") {
          bodyFormData.append(
            key,
            `FREQ=MONTHLY;BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
          console.log(
            "Montly_Rec_rule",
            `FREQ=MONTHLY;BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
        }
        if (recurrence_rule_to_Set === "Quaterly") {
          bodyFormData.append(
            key,
            `FREQ=MONTHLY;BYMONTH=${q1_month + 1},${q2_month + 1},${
              q3_month + 1
            },${q4_month + 1};BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
          console.log(
            "quaterly",
            `FREQ=MONTHLY;BYMONTH=${q1_month + 1},${q2_month + 1},${
              q3_month + 1
            },${q4_month + 1};BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
        }
        if (recurrence_rule_to_Set === "Half Yearly(Financial Year)") {
          bodyFormData.append(
            key,
            `FREQ=MONTHLY;BYMONTH=${half_fy1 + 1},${
              half_fy2 + 1
            };BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
          console.log(
            "half_fy",
            `FREQ=MONTHLY;BYMONTH=${half_fy1 + 1},${
              half_fy2 + 1
            };BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
        }
        if (recurrence_rule_to_Set === "Half Yearly(Calendar Year)") {
          bodyFormData.append(
            key,
            `FREQ=MONTHLY;BYMONTH=${half_cy1 + 1},${
              half_cy2 + 1
            };BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
          console.log(
            "half_fy",
            `FREQ=MONTHLY;BYMONTH=${half_cy1 + 1},${
              half_cy2 + 1
            };BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
        }
        if (recurrence_rule_to_Set === "Annually(Financial Year)") {
          bodyFormData.append(
            key,
            `FREQ=YEARLY;BYMONTH=${annually_fy};BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
          console.log(
            "annul_fy",
            `FREQ=YEARLY;BYMONTH=${annually_fy};BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
        }
        if (recurrence_rule_to_Set === "Annually(Calendar Year)") {
          bodyFormData.append(
            key,
            `FREQ=YEARLY;BYMONTH=${annually_cy};BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
          console.log(
            "annul_cy",
            `FREQ=YEARLY;BYMONTH=${annually_cy};BYMONTHDAY=${recurrence_monlthy.substring(
              0,
              recurrence_monlthy.length - 2
            )}`
          );
        }
      }
      if (key === "client_mails") {
        formData.client_mails = tags.length === 0 ? "null" : tags.join(",");
        bodyFormData.append(key, tags.length === 0 ? "null" : tags.join(","));
        console.log(
          "client_mails",
          tags.length === 0 ? "null" : tags.join(",")
        );
      }
      if (key === "due_date") {
        bodyFormData.append(key, moment(due_date).format("YYYY-MM-DD"));
      } else if ((key, formData[key] !== "")) {
        bodyFormData.append(key, formData[key]);
      }
    }
    console.log("bodyFomData", formData);
    const apiUrl = `https://api.startupfino.in/task-manager/api/task-management/${selected_task.sr_id}`;
    axios
      .patch(apiUrl, bodyFormData)
      .then((response) => {
        console.log("Recurring Task added successfully:", response.data);
        handleClosee();
        handleUpdate();
        toast.success("Reccurring Task Updated Successfully");
      })
      .catch((error) => {
        // Handle errors that occur during the request
        console.error("Error adding task:", error);
      });
  };
  useEffect(() => {
    console.log("useeffectcakked", recurrence_rule);

    if (
      recurrence_rule !== null &&
      recurrence_rule !== undefined &&
      recurrence_rule !== ""
    ) {
      if (recurrence_rule_to_Set === "Monthly") {
        console.log("-----------------------------------------------------------------------------")
        const inputString = recurrence_rule;
        // Extracting BYMONTHDAY
        const byMonthDayMatch = inputString.match(/BYMONTHDAY=([0-9,]+)/);
        const byMonthDayValue = byMonthDayMatch ? byMonthDayMatch[1] : "";
        let arrnum = [
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11st",
          "12nd",
          "13rd",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th",
          "31st",
        ];
        setrecurrence_monlthy(arrnum[byMonthDayValue - 1]);
      }
      if (recurrence_rule_to_Set === "Quaterly") {
        console.log("inside quaterly");
        const inputString = recurrence_rule;
        // Extracting BYMONTH
        const byMonthMatch = inputString?.match(/BYMONTH=([0-9,]+)/);
        const byMonthValue = byMonthMatch ? byMonthMatch[1] : "";
        const byMonthArray = byMonthValue?.split(",").map(Number);

        // Extracting BYMONTHDAY
        const byMonthDayMatch = inputString?.match(/BYMONTHDAY=([0-9,]+)/);
        const byMonthDayValue = byMonthDayMatch ? byMonthDayMatch[1] : "";

        // Extracting FREQ
        const freqMatch = inputString?.match(/FREQ=([^;]+)/);
        const freqValue = freqMatch ? freqMatch[1] : "";

        console.log("EXEBYMONTH:", byMonthArray);
        console.log("BYMONTHDAY:", byMonthDayValue);
        console.log("FREQ:", freqValue);
        let arrnum = [
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11st",
          "12nd",
          "13rd",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th",
          "31st",
        ];
        setrecurrence_monlthy(arrnum[byMonthDayValue - 1]);
        setq1_month(byMonthMatch ? byMonthArray[0] - 1 : 3);
        setq2_month(byMonthMatch ? byMonthArray[1] - 1 : 6);
        setq3_month(byMonthMatch ? byMonthArray[2] - 1 : 9);
        setq4_month(byMonthMatch ? byMonthArray[3] - 1 : 0);
      }
      if (recurrence_rule_to_Set === "Half Yearly(Financial Year)") {
        const inputString = recurrence_rule;
        // Extracting BYMONTH
        const byMonthMatch = inputString.match(/BYMONTH=([0-9,]+)/);
        const byMonthValue = byMonthMatch ? byMonthMatch[1] : "";
        const byMonthArray = byMonthValue.split(",").map(Number);

        // Extracting BYMONTHDAY
        const byMonthDayMatch = inputString.match(/BYMONTHDAY=([0-9,]+)/);
        const byMonthDayValue = byMonthDayMatch ? byMonthDayMatch[1] : "";

        // Extracting FREQ
        const freqMatch = inputString.match(/FREQ=([^;]+)/);
        const freqValue = freqMatch ? freqMatch[1] : "";

        console.log("EXEBYMONTH:", byMonthArray);
        console.log("BYMONTHDAY:", byMonthDayValue);
        console.log("FREQ:", freqValue);
        let arrnum = [
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11st",
          "12nd",
          "13rd",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th",
          "31st",
        ];
        setrecurrence_monlthy(arrnum[byMonthDayValue - 1]);
        sethalf_fy1(byMonthArray ? byMonthArray[0] - 1 : 3);
        sethalf_fy2(byMonthArray ? byMonthArray[1] - 1 : 9);
      }
      if (recurrence_rule_to_Set === "Half Yearly(Calendar Year)") {
        console.log("--------------------------------+--------+-----------------------------------")

        const inputString = recurrence_rule;
        // Extracting BYMONTH
        const byMonthMatch = inputString.match(/BYMONTH=([0-9,]+)/);
        const byMonthValue = byMonthMatch ? byMonthMatch[1] : "";
        const byMonthArray = byMonthValue.split(",").map(Number);

        // Extracting BYMONTHDAY
        const byMonthDayMatch = inputString.match(/BYMONTHDAY=([0-9,]+)/);
        const byMonthDayValue = byMonthDayMatch ? byMonthDayMatch[1] : "";

        // Extracting FREQ
        const freqMatch = inputString.match(/FREQ=([^;]+)/);
        const freqValue = freqMatch ? freqMatch[1] : "";

        console.log("EXEBYMONTH:", byMonthArray);
        console.log("BYMONTHDAY:", byMonthDayValue);
        console.log("FREQ:", freqValue);
        let arrnum = [
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11st",
          "12nd",
          "13rd",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th",
          "31st",
        ];
        setrecurrence_monlthy(arrnum[byMonthDayValue - 1]);
        sethalf_cy1(byMonthArray ? byMonthArray[0] - 1 : 0);
        sethalf_cy2(byMonthArray ? byMonthArray[1] - 1 : 6);
      }
      if (recurrence_rule_to_Set === "Annually(Financial Year)") {
        console.log("--------------------------------+--------+-----------------------------------")

        const inputString = recurrence_rule;
        // Extracting BYMONTH
        const byMonthMatch = inputString.match(/BYMONTH=([0-9,]+)/);
        const byMonthValue = byMonthMatch ? byMonthMatch[1] : "";
        const byMonthArray = byMonthValue.split(",").map(Number);

        // Extracting BYMONTHDAY
        const byMonthDayMatch = inputString.match(/BYMONTHDAY=([0-9,]+)/);
        const byMonthDayValue = byMonthDayMatch ? byMonthDayMatch[1] : "";

        // Extracting FREQ
        const freqMatch = inputString.match(/FREQ=([^;]+)/);
        const freqValue = freqMatch ? freqMatch[1] : "";

        console.log("EXEBYMONTH:", byMonthArray);
        console.log("BYMONTHDAY:", byMonthDayValue);
        console.log("FREQ:", freqValue);
        let arrnum = [
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11st",
          "12nd",
          "13rd",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th",
          "31st",
        ];
        setrecurrence_monlthy(arrnum[byMonthDayValue - 1]);
        setannually_fy(byMonthArray ? byMonthArray[0] - 1 : 0);
      }
      if (recurrence_rule_to_Set === "Annually(Calendar Year)") {
        console.log("--------------------------------+--------+-----------------------------------")
        const inputString = recurrence_rule;
        // Extracting BYMONTH
        const byMonthMatch = inputString.match(/BYMONTH=([0-9,]+)/);
        const byMonthValue = byMonthMatch ? byMonthMatch[1] : "";
        const byMonthArray = byMonthValue.split(",").map(Number);

        // Extracting BYMONTHDAY
        const byMonthDayMatch = inputString.match(/BYMONTHDAY=([0-9,]+)/);
        const byMonthDayValue = byMonthDayMatch ? byMonthDayMatch[1] : "";

        // Extracting FREQ
        const freqMatch = inputString.match(/FREQ=([^;]+)/);
        const freqValue = freqMatch ? freqMatch[1] : "";

        console.log("EXEBYMONTH:", byMonthArray);
        console.log("BYMONTHDAY:", byMonthDayValue);
        console.log("FREQ:", freqValue);
        let arrnum = [
          "1st",
          "2nd",
          "3rd",
          "4th",
          "5th",
          "6th",
          "7th",
          "8th",
          "9th",
          "10th",
          "11st",
          "12nd",
          "13rd",
          "14th",
          "15th",
          "16th",
          "17th",
          "18th",
          "19th",
          "20th",
          "21st",
          "22nd",
          "23rd",
          "24th",
          "25th",
          "26th",
          "27th",
          "28th",
          "29th",
          "30th",
          "31st",
        ];
        setrecurrence_monlthy(arrnum[byMonthDayValue - 1]);
        setannually_cy(byMonthArray ? byMonthArray[0] - 1 : 0);
      }
    }
  }, [recurrence_rule]);
  console.log(recurrence_monlthy, "recurrence_monlthy");

  console.log("ta", task_assignee, task_owner);
  return (
    <Box
      sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
      display="flex"
    >
      {nextbtn === false ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <Autocomplete
                name="task_company_name"
                id="controllable-states-demo"
                value={task_company_name}
                freeSolo
                onInputChange={(event, newValue) => {
                  setTask_company_name(newValue);
                  {
                    formData.task_company_name = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                options={company_name}
                renderInput={(params) => (
                  <TextField {...params} label="Company Name" />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <Autocomplete
                name="task_name"
                disablePortal
                id="controllable-states-demo"
                value={task_name}
                onChange={(event, newValue) => {
                  setTask_name(newValue);
                  {
                    formData.task_name = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                options={task_names}
                renderInput={(params) => (
                  <TextField {...params} label="Task Name" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <TextField
                name="task_description"
                id="controllable-states-demo"
                value={task_description}
                onChange={(event, newValue) => {
                  setTask_description(event.target.value);
                  {
                    formData.task_description = event.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Task Description"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <Autocomplete
                name="task_owner"
                id="combo-box-demo"
                value={task_owner}
                onChange={(event, newValue) => {
                  setTask_owner(newValue);
                  {
                    formData.task_owner = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                options={teamates_data}
                renderInput={(params) => (
                  <TextField {...params} label="Task Owner" />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <Autocomplete
                name="task_assignee"
                id="combo-box-demo"
                value={task_assignee}
                onChange={(event, newValue) => {
                  setTask_assignee(newValue);
                  {
                    formData.task_assignee = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                options={teamates_data}
                renderInput={(params) => (
                  <TextField {...params} label="Task Assignee" />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  name="due_date"
                  label="Due Date"
                  format="DD/MM/YYYY"
                  value={moment.parseZone(due_date)}
                  onChange={(newTo_date) => {
                    setDue_date(newTo_date);
                    formData.due_date = moment(newTo_date).format("YYYY-MM-DD");
                    handleFormDataChange;
                  }}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ margin: 1 }} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <Autocomplete
                name="billing_info"
                id="controllable-states-demo"
                value={billing_info}
                onChange={(event, newValue) => {
                  setBilling_type(newValue);
                  {
                    formData.billing_info = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                options={["Billiable", "Non Billiable", "Reimbursement"]}
                renderInput={(params) => (
                  <TextField {...params} label="Billing Type" />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <TextField
                type="number"
                name="billing_amount"
                id="controllable-states-demo"
                value={billing_amount}
                onChange={(event, newValue) => {
                  setBilling_amount(event.target.value);
                  {
                    formData.billing_amount = event.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Billing Amount"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <Autocomplete
                name="task_status"
                id="controllable-states-demo"
                value={task_status}
                onChange={(event, newValue) => {
                  setTask_status(newValue);
                  {
                    formData.task_status = newValue;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                options={[
                  "Completed",
                  "Initiated",
                  "In the Process",
                  "Data is Pending from Client",
                  "Not Started",
                  "Cancelled",
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Status Of Task" />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              <TextField
                name="remarks"
                id="controllable-states-demo"
                value={remarks}
                onChange={(event, newValue) => {
                  setRemarks(event.target.value);
                  {
                    formData.remarks = event.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Remarks"
                fullWidth
              />
            </Grid>
          </Grid>

          <TextField
            name="attachment"
            onChange={handleSelectFile}
            type="file"
            hidden
            sx={{ mt: 1 }}
            helperText={
              attachment !== "" ? (
                <Button
                  onClick={() => {
                    window.open(selected_task.attachment);
                  }}
                >
                  View File
                </Button>
              ) : (
                "No file"
              )
            }
          />

          {/* <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12} md={12} xl={12}>
              {console.log(client_mail)}
              <Autocomplete
                multiple
                id="tags-filled"
                options={client_mail_list}
                value={selected_task.client_mail}
                freeSolo
                onChange={handleChangeMail}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Client Mail"
                    value={tags.join(",")}
                  />
                )}
              />
            </Grid>
          </Grid> */}

          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={6} md={6} xl={6}>
              {console.log(recurrence_rule_to_Set, "rr")}
              <Autocomplete
                name="recurrence_rule_to_Set"
                value={recurrence_rule_to_Set}
                onChange={(event, newValue) => {
                  setTimeout(() => {
                    setq1_month(3);
                    setq2_month(6);
                    setq3_month(9);
                    setq4_month(0);
                    sethalf_cy1(0);
                    sethalf_cy2(6);
                    sethalf_fy1(3);
                    sethalf_fy2(9);
                    setannually_cy(0);
                    setannually_fy(3);
                    setrecurrence_rule_to_Set(newValue);
                    // Assuming formData is a state variable, update it using the functional update pattern
                    formData["recurring_period"] = newValue;
                  }, 1000);
                }}
                options={[
                  "Monthly",
                  "Quaterly",
                  "Half Yearly(Financial Year)",
                  "Half Yearly(Calendar Year)",
                  "Annually(Financial Year)",
                  "Annually(Calendar Year)",
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Recurrence Rule" />
                )}
              />
            </Grid>

            {recurrence_rule_to_Set === "Monthly" && (
              <>
                <Grid item xs={12} lg={6} md={6} xl={6}>
                  <Autocomplete
                    name="rec_month"
                    value={recurrence_monlthy}
                    onChange={(event, newValue) => {
                      setrecurrence_monlthy(newValue);
                    }}
                    options={[
                      "1st",
                      "2nd",
                      "3rd",
                      "4th",
                      "5th",
                      "6th",
                      "7th",
                      "8th",
                      "9th",
                      "10th",
                      "11th",
                      "12th",
                      "13th",
                      "14th",
                      "15th",
                      "16th",
                      "17th",
                      "18th",
                      "19th",
                      "20th",
                      "21st",
                      "22nd",
                      "23rd",
                      "24th",
                      "25th",
                      "26th",
                      "27th",
                      "28th",
                      "29th",
                      "30th",
                      "31st",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Recurrence Date" />
                    )}
                  />
                </Grid>
                <Stack sx={{ width: "100%" }} spacing={2} marginTop={"10px"}>
                  {recurrence_monlthy !== null && (
                    <Alert severity="info">
                      <AlertTitle>
                        Monthly-Task For {task_company_name}
                      </AlertTitle>
                      This Task of {task_name} will be repeated on every
                      <strong> {recurrence_monlthy} of Month</strong>
                      <br />
                      Task Assignee-:<strong> {task_assignee}</strong>
                      <br />
                      {task_description}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
            {recurrence_rule_to_Set === "Quaterly" && (
              <>
                <Grid item xs={12} lg={6} md={6} xl={6}>
                  <Autocomplete
                    name="rec_month"
                    value={recurrence_monlthy}
                    onChange={(event, newValue) => {
                      setrecurrence_monlthy(newValue);
                    }}
                    options={[
                      "1st",
                      "2nd",
                      "3rd",
                      "4th",
                      "5th",
                      "6th",
                      "7th",
                      "8th",
                      "9th",
                      "10th",
                      "11th",
                      "12th",
                      "13th",
                      "14th",
                      "15th",
                      "16th",
                      "17th",
                      "18th",
                      "19th",
                      "20th",
                      "21st",
                      "22nd",
                      "23rd",
                      "24th",
                      "25th",
                      "26th",
                      "27th",
                      "28th",
                      "29th",
                      "30th",
                      "31st",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Recurrence Date" />
                    )}
                  />
                </Grid>
                <Grid container spacing={2.5} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      {console.log(
                        q1_month,
                        q2_month,
                        q3_month,
                        q4_month,
                        "sdsdsad",
                        moment(q1_month).parseZone()
                      )}
                      <DatePicker
                        name="q1_month"
                        views={["month"]}
                        format="MM-MMMM"
                        defaultCalendarMonth={
                          q1_month === undefined || q1_month === NaN ||q1_month === null
                            ? moment().month(3)
                            : moment().month(q1_month)
                        }
                        defaultValue={
                          q1_month === undefined || q1_month === NaN ||q1_month === null
                            ? moment().month(3)
                            : moment().month(q1_month)
                        }
                        label="Customize Q1"
                        onChange={(newTo_date) => {
                          setq1_month(moment(newTo_date).month());
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        minDate={moment().month(3)}
                        maxDate={moment().month(5)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="q2_month"
                        views={["month"]}
                        format="MM-MMMM"
                        defaultCalendarMonth={
                          q2_month === undefined || q2_month === NaN || q2_month === null
                            ? moment().month(6)
                            : moment().month(q2_month)
                        }
                        defaultValue={
                          q2_month === undefined || q2_month === NaN || q2_month === null
                            ? moment().month(6)
                            : moment().month(q2_month)
                        }
                        label="Customize Q2"
                        onChange={(newTo_date) => {
                          setq2_month(moment(newTo_date).month());
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        minDate={moment().month(6)}
                        maxDate={moment().month(8)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="q3_month"
                        views={["month"]}
                        label="Customize Q3"
                        defaultCalendarMonth={
                          q3_month === undefined || q3_month === NaN || q3_month === null
                            ? moment().month(9)
                            : moment().month(q3_month)
                        }
                        defaultValue={
                          q3_month === undefined || q3_month === NaN || q3_month === null
                            ? moment().month(9)
                            : moment().month(q3_month)
                        }
                        format="MM-MMMM"
                        onChange={(newTo_date) => {
                          setq3_month(moment(newTo_date).month());
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        minDate={moment().month(9)}
                        maxDate={moment().month(11)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="QE"
                        views={["month"]}
                        label="Customize Q4"
                        format="MM-MMMM"
                        defaultCalendarMonth={
                          q4_month === undefined || q4_month === NaN || q4_month === null
                            ? moment().month(0)
                            : moment().month(q4_month)
                        }
                        defaultValue={
                          q4_month === undefined || q4_month === NaN || q4_month === null
                            ? moment().month(0)
                            : moment().month(q4_month)
                        }
                        onChange={(newTo_date) => {
                          setq4_month(moment(newTo_date).month());
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        minDate={moment().month(0)}
                        maxDate={moment().month(2)}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Stack sx={{ width: "100%" }} spacing={2} marginTop={"10px"}>
                  {recurrence_monlthy !== null && (
                    <Alert severity="info">
                      <AlertTitle>
                        Quaterly-Task For {task_company_name}
                      </AlertTitle>
                      This Task of {task_name} will be repeated on{" "}
                      <strong>{recurrence_monlthy} every of Quater</strong>
                      <br />
                      Task Assignee-:<strong> {task_assignee}</strong>
                      <br />
                      {task_description}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
            {recurrence_rule_to_Set === "Half Yearly(Financial Year)" && (
              <>
                <Grid item xs={12} lg={6} md={6} xl={6}>
                  <Autocomplete
                    name="rec_month"
                    value={recurrence_monlthy}
                    onChange={(event, newValue) => {
                      setrecurrence_monlthy(newValue);
                    }}
                    options={[
                      "1st",
                      "2nd",
                      "3rd",
                      "4th",
                      "5th",
                      "6th",
                      "7th",
                      "8th",
                      "9th",
                      "10th",
                      "11th",
                      "12th",
                      "13th",
                      "14th",
                      "15th",
                      "16th",
                      "17th",
                      "18th",
                      "19th",
                      "20th",
                      "21st",
                      "22nd",
                      "23rd",
                      "24th",
                      "25th",
                      "26th",
                      "27th",
                      "28th",
                      "29th",
                      "30th",
                      "31st",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Recurrence Date" />
                    )}
                  />
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    {console.log("half-fy1", half_fy1, half_fy2)}
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="q1_month"
                        views={["month"]}
                        format="MM-MMMM"
                        defaultCalendarMonth={half_fy1===undefined|| half_fy1===NaN || half_fy1===null ? moment().month(3):moment().month(half_fy1)}
                        defaultValue={half_fy1===undefined|| half_fy1===NaN || half_fy1===null ? moment().month(3):moment().month(half_fy1)}
                        value={half_fy1===undefined|| half_fy1===NaN || half_fy1===null ? moment().month(3):moment().month(half_fy1)}
                        label="Customize 04-08"
                        onChange={(newTo_date) => {
                          sethalf_fy1(moment(newTo_date).month());
                          sethalf_fy2(
                            moment(newTo_date).add(6, "months").month()
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        minDate={moment().month(3)}
                        maxDate={moment().month(8)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      {console.log("half_fy2", half_fy2)}
                      <DatePicker
                        name="half_fy2"
                        views={["month"]}
                        defaultCalendarMonth={half_fy2===undefined|| half_fy2===NaN || half_fy2===null ? moment().month(9):moment().month(half_fy2)}
                        defaultValue={half_fy2===undefined|| half_fy2===NaN || half_fy2===null ? moment().month(9):moment().month(half_fy2)}
                        value={half_fy2===undefined|| half_fy2===NaN || half_fy2===null ? moment().month(9):moment().month(half_fy2)}
                        disabled={true}
                        format="MM-MMMM"
                        label="View 09-03"
                        // onChange={(newTo_date) => {
                        //   sethalf_fy2(moment(newTo_date).month() + 1);
                        // }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack sx={{ width: "100%" }} spacing={2} marginTop={"10px"}>
                  {recurrence_monlthy !== null && (
                    <Alert severity="info">
                      <AlertTitle>
                        Half-Yearly(FY)-Task For {task_company_name}
                      </AlertTitle>
                      This Task of {task_name} will be repeated on every{" "}
                      <strong>
                        {recurrence_monlthy} of Half Financial Year
                      </strong>
                      <br />
                      Task Assignee-:<strong> {task_assignee}</strong>
                      <br />
                      {task_description}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
            {recurrence_rule_to_Set === "Half Yearly(Calendar Year)" && (
              <>
                <Grid item xs={12} lg={6} md={6} xl={6}>
                  <Autocomplete
                    name="rec_month"
                    value={recurrence_monlthy}
                    onChange={(event, newValue) => {
                      setrecurrence_monlthy(newValue);
                    }}
                    options={[
                      "1st",
                      "2nd",
                      "3rd",
                      "4th",
                      "5th",
                      "6th",
                      "7th",
                      "8th",
                      "9th",
                      "10th",
                      "11th",
                      "12th",
                      "13th",
                      "14th",
                      "15th",
                      "16th",
                      "17th",
                      "18th",
                      "19th",
                      "20th",
                      "21st",
                      "22nd",
                      "23rd",
                      "24th",
                      "25th",
                      "26th",
                      "27th",
                      "28th",
                      "29th",
                      "30th",
                      "31st",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Recurrence Date" />
                    )}
                  />
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    {console.log("half_Cyt", half_cy1, half_cy2)}
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="q1_month"
                        views={["month"]}
                        format="MM-MMMM"
                        defaultCalendarMonth={
                          half_cy1 === undefined || half_cy1 === NaN 
                            ? moment().month(0)
                            : moment().month(half_cy1)
                        }
                        defaultValue={
                          half_cy1 === undefined || half_cy1 === NaN
                            ? moment().month(0)
                            : moment().month(half_cy1)
                        }
                        value={moment().month(half_cy1)}
                        label="Customize 04-08"
                        onChange={(newTo_date) => {
                          sethalf_cy1(moment(newTo_date).month());
                          sethalf_cy2(
                            moment(newTo_date).add(6, "months").month()
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        // minDate={moment().month(0)}
                        // maxDate={moment().month(5)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      {console.log("half_cy2", half_cy2)}
                      <DatePicker
                        name="half_cy2"
                        views={["month"]}
                        defaultCalendarMonth={moment().month(half_cy2)}
                        defaultValue={moment().month(half_cy2)}
                        value={moment().month(half_cy2)}
                        format="MM-MMMM"
                        label="View 09-03"
                        onChange={(newTo_date) => {
                          sethalf_cy2(moment(newTo_date).month());
                          sethalf_cy1(
                            moment(newTo_date).subtract(6, "months").month()
                          );
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                        minDate={moment().month(6)}
                        maxDate={moment().month(11)}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack sx={{ width: "100%" }} spacing={2} marginTop={"10px"}>
                  {recurrence_monlthy !== null && (
                    <Alert severity="info">
                      <AlertTitle>
                        Half-Yearly(CY)-Task For {task_company_name}
                      </AlertTitle>
                      This Task of {task_name} will be repeated on every{" "}
                      <strong>
                        {recurrence_monlthy} of Half Calendar Year
                      </strong>
                      <br />
                      Task Assignee-:<strong> {task_assignee}</strong>
                      <br />
                      {task_description}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
            {recurrence_rule_to_Set === "Annually(Calendar Year)" && (
              <>
                <Grid item xs={12} lg={6} md={6} xl={6}>
                  <Autocomplete
                    name="rec_month"
                    value={recurrence_monlthy}
                    onChange={(event, newValue) => {
                      setrecurrence_monlthy(newValue);
                    }}
                    options={[
                      "1st",
                      "2nd",
                      "3rd",
                      "4th",
                      "5th",
                      "6th",
                      "7th",
                      "8th",
                      "9th",
                      "10th",
                      "11th",
                      "12th",
                      "13th",
                      "14th",
                      "15th",
                      "16th",
                      "17th",
                      "18th",
                      "19th",
                      "20th",
                      "21st",
                      "22nd",
                      "23rd",
                      "24th",
                      "25th",
                      "26th",
                      "27th",
                      "28th",
                      "29th",
                      "30th",
                      "31st",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Recurrence Date" />
                    )}
                  />
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="q1_month"
                        views={["month"]}
                        format="MM-MMMM"
                        defaultCalendarMonth={moment().month(0)}
                        defaultValue={moment().month(0)}
                        label="Customize Month(CY)"
                        onChange={(newTo_date) => {
                          setannually_cy(moment(newTo_date).month() + 1);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack sx={{ width: "100%" }} spacing={2} marginTop={"10px"}>
                  {recurrence_monlthy !== null && (
                    <Alert severity="info">
                      <AlertTitle>
                        Annually(CY)-Task For {task_company_name}
                      </AlertTitle>
                      This Task of {task_name} will be repeated on every{" "}
                      <strong>{recurrence_monlthy} of Calendar Year</strong>
                      <br />
                      Task Assignee-:<strong> {task_assignee}</strong>
                      <br />
                      {task_description}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
            {recurrence_rule_to_Set === "Annually(Financial Year)" && (
              <>
                <Grid item xs={12} lg={6} md={6} xl={6}>
                  <Autocomplete
                    name="rec_month"
                    value={recurrence_monlthy}
                    onChange={(event, newValue) => {
                      setrecurrence_monlthy(newValue);
                    }}
                    options={[
                      "1st",
                      "2nd",
                      "3rd",
                      "4th",
                      "5th",
                      "6th",
                      "7th",
                      "8th",
                      "9th",
                      "10th",
                      "11th",
                      "12th",
                      "13th",
                      "14th",
                      "15th",
                      "16th",
                      "17th",
                      "18th",
                      "19th",
                      "20th",
                      "21st",
                      "22nd",
                      "23rd",
                      "24th",
                      "25th",
                      "26th",
                      "27th",
                      "28th",
                      "29th",
                      "30th",
                      "31st",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Recurrence Date" />
                    )}
                  />
                </Grid>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={12} lg={6} md={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        name="annually_fy"
                        views={["month"]}
                        format="MM-MMMM"
                        defaultCalendarMonth={moment().month(3)}
                        defaultValue={moment().month(3)}
                        label="Customize Month(CY)"
                        onChange={(newTo_date) => {
                          setannually_fy(moment(newTo_date).month() + 1);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ margin: 1 }} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stack sx={{ width: "100%" }} spacing={2} marginTop={"10px"}>
                  {recurrence_monlthy !== null && (
                    <Alert severity="info">
                      <AlertTitle>
                        Annually(FY)-Task For {task_company_name}
                      </AlertTitle>
                      This Task of {task_name} will be repeated on every{" "}
                      <strong>{recurrence_monlthy} of Financial Year</strong>
                      <br />
                      Task Assignee-:<strong> {task_assignee}</strong>
                      <br />
                      {task_description}
                    </Alert>
                  )}
                </Stack>
              </>
            )}
          </Grid>
          <Box
            container
            m={1}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ margin: 1 }}
          >
            <Button variant="contained" onClick={handlePrev} sx={{ mr: 2 }}>
              Pervious
            </Button>
            <Button variant="contained" onClick={handleAddTask}>
              Update Task
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Edit_Recurring_Data;
