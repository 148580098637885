import React from "react";
import { useEffect, useState } from "react";
import ClippedDrawer from "../sidebar/sidebar_list";
import { Box, Checkbox, Grid, TextField, Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import iconlog from "../Logo/icon.png"

import { Fade, Typography } from "@mui/material";
const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage
const ComplianceNotif = () => {
  const [datas, setDatas] = useState([]);
  const [company_noti, setcompany_noti] = useState([]);
  const [company_data, setcompany_Data] = useState([]);
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [role, setRole] = useState("");
  const [id, setId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    company_name: "",
    role: "",
    email: "",
    phone: "",
    email_alerts: true,
    whatsapp_alerts: true,
  });

  const fetchData = () => {
    axios
      .get(
        "https://api.startupfino.in/internal_compliance/internal-notification/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setcompany_noti(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact list:", error);
      });
    axios
      .get(
        "https://api.startupfino.in/internal_compliance/company_credential/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setcompany_Data(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact list:", error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  useEffect(() => {
    const data = company_noti?.map((row) => {
      return {
        companyname: row.company_name,
        client: row.name,
        desg: row.role,
        contact: row,
        alerts: row,
        edit: row,
        is_wa: row.whatsapp_alerts
          ? "WhatsApp Alert Active"
          : "WhatsApp Alert Inactive",
        is_ma: row.email_alerts ? "Mail Alert Active" : "Mail Alert Inactive",
      };
    });
    setDatas(data);
  }, [company_noti]);

  console.log("comooo", company_noti);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAdd = () => {
    // Use formData to make the API request
    const postData = JSON.stringify({
      name: name,
      company: id,
      company_name: companyName,
      role: role,
      email: email,
      phone: contact,
      email_alerts: true,
      whatsapp_alerts: true,
    });
    const apiUrl =
      "https://api.startupfino.in/internal_compliance/internal-notification/";

    axios
      .post(apiUrl, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success("Added Successfully");
        fetchData();
        handleClose();
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        // Handle error as needed
      });
  };

  const handleWhatsapp = (e, row) => {
    var waspp_noti = new FormData();
    let message;
    if (row.whatsapp_alerts == true) {
      row.whatsapp_alerts = false;
      message = `Disabling WhatsApp Alerts for \n ${row.name} at \n ${row.company_name}`;
      console.log("Update", row.whatsapp_alerts);
    } else if (row.whatsapp_alerts == false) {
      row.whatsapp_alerts = true;
      message = `Enabling WhatsApp Alerts for \n ${row.name} at \n ${row.company_name}`;
      console.log("Update", row.whatsapp_alerts);
      //
    }
    waspp_noti.append("name", row.name);
    waspp_noti.append("role", row.role);
    waspp_noti.append("email", row.email);
    waspp_noti.append("phone", row.phone);
    waspp_noti.append("whatsapp_alerts", row.whatsapp_alerts);
    console.log("check", row.whatsapp_alerts);
    const apiUrl =
    `https://api.startupfino.in/internal_compliance/internal-notification/${row.id}/`;
    axios
    .patch(apiUrl, waspp_noti, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      console.log("Data posted successfully:", response.data);
      toast.success(message);
      fetchData();
      setOpen(false);
    });


  };
  const handleEmail = (e, row) => {
    var email_noti = new FormData();
    let message;
    if (row.email_alerts == true) {
      row.email_alerts = false;
      message = `Disabling Email Alerts for \n${row.name} at \n${row.company_name}`;
      console.log("Update", row.email_alerts);
    } else if (row.email_alerts == false) {
      row.email_alerts = true;
      message = `Enabling Email Alerts for \n${row.name} at \n${row.company_name}`;
      console.log("Update", row.email_alerts);
    }
    email_noti.append("name", row.name);
    email_noti.append("role", row.role);
    email_noti.append("email", row.email);
    email_noti.append("phone", row.phone);
    email_noti.append("email_alerts", row.email_alerts);
    console.log("check", row.whatsapp_alerts);
    const apiUrl =
    `https://api.startupfino.in/internal_compliance/internal-notification/${row.id}/`;
    axios
    .patch(apiUrl, email_noti, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      console.log("Data posted successfully:", response.data);
      toast.success(message);
      fetchData();
      setOpen(false);
    });
  };
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    searchAlwaysOpen: true,
    filterType: "multiselect",
  };
  const columns = [
    {
      name: "companyname",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "client",
      label: "Client",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, tableMeta, rowIndex) => {
          const remark = tableMeta.rowData[2];
          return (
            <Tooltip
              title={remark}
              placement="right"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 300 }}
            >
              <span>{data}</span>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "desg",
      label: "Role",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "contact",
      label: "Contact Detail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <ContactPhoneIcon sx={{ mr: 1, color: "#787276" }} />
                <Typography>: {data.phone}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <ContactMailIcon sx={{ mr: 1, color: "#787276" }} />
                <Typography>: {data.email}</Typography>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "alerts",
      label: "Alerts",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          console.log("datsssa", data);
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <WhatsAppIcon sx={{ mr: 1, color: "green" }} />
                <Checkbox
                  checked={data.whatsapp_alerts}
                  onClick={(e) => {
                    handleWhatsapp(e, data);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <EmailSharpIcon sx={{ mr: 1, color: "coral" }} />
                <Checkbox
                  checked={data.email_alerts}
                  onClick={(e) => {
                    handleEmail(e, data);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "edit",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return <Update row={data} fetchData={fetchData}/>;
        },
      },
    },
    {
      name: "is_wa",
      label: "WhatsApp Alerts",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "is_ma",
      label: "Mail Alerts",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
  ];
  const company_opts = company_data?.map((item) => item.company_name);
  let filter_opts = [
    ...new Set(company_data?.map((item) => item.company_name)),
  ];
  return (
    <Box sx={{ display: "flex" }}>
      <ClippedDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pr: 3,
          pt: 2,
          pl: 2,
          width: "100%",
          overflowX: "auto",
        }}
      >
        <Button variant="contained" sx={{ mb: 2 }} onClick={handleClickOpen}>
          Add
        </Button>
        <br />
        <MUIDataTable
          title={"Client Notification List"}
          data={datas}
          columns={columns}
          options={options}
        />
        <Dialog
          open={open}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
        >
          {" "}
          <DialogTitle>Add Contact</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <br></br>
                <Autocomplete
                  label="Company Name"
                  fullWidth
                  id="combo-box-demo"
                  value={companyName || ""}
                  onChange={(e, newVal) => {
                    setCompanyName(newVal);
                    let ids = company_data?.filter(
                      (item) => item?.company_name === newVal
                    );
                    setId(ids[0]?.company);
                    console.log("ids.company", ids[0]?.company);
                  }}
                  options={filter_opts}
                  renderInput={(params) => (
                    <TextField {...params} label="Company Name" />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  name="name"
                  value={name}
                  onChange={(e, val) => {
                    setName(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  sx={{ pr: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Role"
                  name="name"
                  value={role}
                  onChange={(e, val) => {
                    setRole(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  sx={{ pl: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  name="name"
                  value={email}
                  onChange={(e, val) => {
                    setEmail(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  sx={{ pr: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Phone"
                  name="name"
                  value={contact}
                  onChange={(e, val) => {
                    setContact(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  sx={{ pl: 1 }}
                />
              </Grid>
            </Grid>
            {/* Add other text fields for each property in formData */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAdd}>Add</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ComplianceNotif;
const Update = (props) => {
  const { row,fetchData } = props;
  console.log("row", row);
  // console.log("sdhfaksfd=====", row);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    phone: "",
    email: "",
  });
  const [namee, setNamee] = useState();
  const [id, setId] = useState();
  const [rolee, setRolee] = useState();
  const [phonee, setPhonee] = useState();
  const [emaile, setEmaile] = useState();
  useEffect(() => {
    setNamee(row.name);
    setId(row.id);
    setRolee(row.role);
    setPhonee(row.phone);
    setEmaile(row.email);
  }, [row]);
  const handleFormDataChange = (e) => {
    console.log(e);
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
    console.log(name);
    console.log(valuee);
    console.log("HEllo WORLD");
  };
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log("rows are", row);

  // const handleDelete = () => {
  //   confirm({ description: "This will permanently delete " })
  //     .then(() => {
  //       setNewrows(null);
  //       console.log("newrows are after delete ",newrows);
  //     })
  //     .catch(() => console.log("Deletion cancelled."));
  // };
  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== "")) {
        bodyFormData.append(key, formData[key]);
      }
    }
    const apiUrl =
      `https://api.startupfino.in/internal_compliance/internal-notification/${row.id}/`;

    axios
      .patch(apiUrl, bodyFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success("Updated Successfully");
        fetchData();
        setOpen(false);
      });
    console.log("form data", bodyFormData);
    const message = "Updating Values";
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={handleClickOpen("paper")}
        variant="contained"
        sx={{ mr: 2 }}
      >
        Edit
      </Button>
      <Dialog
        open={open}
        //onClose={handleClose}
        fullWidth
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            //sx={boxDefault}
          >
            {row.company_name}

            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            //   ref={descriptionElementRef}
            tabIndex={-1}
          >
            {/* <DropContentEsi/> */}
            <Box
              sx={{
                margin: 1,
                justifyContent: "center",
                flexDirection: "column",
              }}
              display="flex"
            >
              <TextField
                required
                value={namee}
                // type="number"
                name="name"
                onChange={(e) => {
                  setNamee(e.target.value);
                  {
                    formData.name = e.target.value;
                    // console.log("Name", e.target.value);
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Authority Person Name"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={rolee}
                // type="number"
                name="role"
                onChange={(e) => {
                  setRolee(e.target.value);
                  {
                    formData.role = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Role"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={phonee}
                name="phone"
                onChange={(e) => {
                  setPhonee(e.target.value);
                  {
                    formData.phone = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Phone Number"
                variant="outlined"
                sx={{ margin: 1 }}
              />
              <TextField
                required
                value={emaile}
                type="email"
                name="email"
                onChange={(e) => {
                  setEmaile(e.target.value);
                  {
                    formData.email = e.target.value;
                  }
                  {
                    handleFormDataChange;
                  }
                }}
                label="Email Address"
                variant="outlined"
                sx={{ margin: 1 }}
              />
            </Box>
            <Box
              container
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              sx={{ margin: 1 }}
            >
              <Button type="submit" onClick={dis} variant="contained">
                SAVE
              </Button>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
