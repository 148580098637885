import React from "react";
import MUIDataTable from "mui-datatables";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import moment from "moment";
import Loader from "../loader/loader";
const Onbaord_Company = () => {
  const columns = [
    {
      name: "name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "date",
      label: "Date of Incorporation",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: true,
        sort: false,
        filterList: ["Active"],
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 100, 500, 1000, 2000],
  };
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [all_companies, set_All_companies] = useState([]);
  const [table_datas, settable_Data] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const companies = await axios(
        "https://api.startupfino.in/company/all-companies/"
      );
      set_All_companies(companies.data);
      setLoading(false);
    };
    fetchData();
  }, []);
  const new_comp = all_companies?.filter((row) => row.source === "StartupFino");
  useEffect(() => {
    const data = new_comp?.map((row) => {
      return {
        name: row.company_name,
        type: row.company_type === null ? "NA" : row.company_type,
        date: moment(row.date_of_incorporation).format("DD/MM/YYYY"),
        active: row.is_company_active === true ? "Active" : "Inactive",
      };
    });
    settable_Data(data);
  }, [all_companies]);
  console.log(all_companies, "all_companies");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <MUIDataTable
          title={"Onboarded Company List"}
          data={table_datas}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
};

export default Onbaord_Company;
