import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart as ChartJS,
} from "chart.js";
import { Typography, Box, Grid,Card} from "@mui/material";
import Divider from "@mui/material/Divider";

ChartJS.register(ArcElement, BarElement, Title, Tooltip, Legend);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
};

const DoughnutChart = (props) => {
  const { comp_task, pend_task, delay_task, datasets } = props;
  console.log("delaytask",delay_task)

  const mappedArray2 = datasets?.company_tasks
    ?.map((item) => {
      return {
        company_name: item.task_assignee,
        total:
          item.completed_company_task +
          item.not_started_company_task +
          item.initialised_company_task +
          item.in_the_process_company_task +
          item.pending_company_task,
        completed: item.completed_company_task,
        notstarted: item.not_started_company_task,
        initiated: item.initialised_company_task,
        intheprocess: item.in_the_process_company_task,
        dataispending: item.pending_company_task,
        ratio:
          (
            (item.completed_company_task /
              (item.completed_company_task +
                item.not_started_company_task +
                item.initialised_company_task +
                item.in_the_process_company_task +
                item.pending_company_task)) *
            100
          ).toFixed(2) + "%",
      };
    })
    .sort((a, b) => parseFloat(b.completed) - parseFloat(a.completed)) // Sort in descending order based on ratio
    .slice(0, 5); // Get the top 3 companies
  console.log("mappedArray", mappedArray2);
  const data = {
    labels: ["Completed", "Pending", "Delayed"],

    datasets: [
      {
        data: [comp_task, pend_task, delay_task],
        backgroundColor: ["#00cc00", "#008ae6", "#ff0000"],
        hoverBackgroundColor: ["#006600", "#006bb3", "#990000"],
      },
    ],
  };
  return (
    <>
      <Grid container>
        <Grid item xs={2} sm={5} md={5} lg={5}>
          <Card sx={{mt:5,ml:2}}>
            <Box sx={{backgroundColor:"orange",pb:2,pt:1}}>

          <Typography fontSize="20px" sx={{ml:1}}>Top Performers List</Typography>
          <Divider/>
          {mappedArray2?.map((performer, index) => (
            <Typography key={index} sx={{ml:2,mt:0.95}} fontSize="14px">
              {`${index + 1}.${performer.company_name} - ${performer.ratio}`}
            </Typography>
          ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={7} sm={7} md={7} lg={7}>
          <Doughnut data={data} options={options} />
        </Grid>
      </Grid>
    </>
    // <div>
    //   <Box sx={centerStyle}> {/* Use MUI's Box component for styling */}
    //     <div>
    //   <Typography fontSize="20px">Top Performers</Typography>
    //       {mappedArray2?.map((performer, index) => (
    //         <Typography key={index} fontSize="13px">
    //           {`${index + 1}.${performer.company_name}-${performer.ratio}`}
    //         </Typography>
    //       ))}
    //     </div>
    //     <Doughnut data={data} options={options}  />
    //   </Box>
    // </div>
  );
};

export default DoughnutChart;
