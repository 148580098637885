import { useRoutes, Navigate } from "react-router-dom";
import MainPage from "../MainPage";
import Task_view from "../taskManagement/task_view/Task_view";
import Task_Name from "../taskNames/tasknamestable";
import Team_Name from "../team_names/Team_nameTab";
import SignIn from "../login/signin";
import Recurring_task_view from "../Recurring_Task/Recurring_task_view";
import Dashbaordmain from "../Dashboard/dashbaordmain";
import Notification from "../Notification/Notify_table";
import TabsNotify from "../Notification/Tabs_Notify";
import Company_tabs from "../CompanyList/CompanyList";
import ComplianceTracker from "../ComplianceTracker/ComplainceTracker";
import ComplianceNotif from "../ComplianceNotifications/ComplianceNotif";
import moment from "moment";
import Prefernce from "../Prefernce/Prefernce";
// ==============================|| MAIN ROUTING ||============================== //

export default function MainRoutes() {
  let abc = localStorage.getItem("api_token");
  let xyz= localStorage.getItem("loginTime")
  var isAuthenticated = Boolean(abc);
  if(xyz){
   var a = moment(xyz)
   var b = moment()
   if(b.diff(a,"hours")>8){
    isAuthenticated=false
   }
   console.log("LoggednInSince",b.diff(a,"minutes"))
  }

  console.log("xyz",xyz)
  return useRoutes([
    {
      path: "/",
      element: isAuthenticated ?  <Navigate to='task_view' /> : <SignIn />
    },
    {
      path: "/signin",
      element: <SignIn />
    },
    {
      path: "/task_view",
      element: <Task_view />,
    },
    {
      path: "/recurring_tasks",
      element: <Recurring_task_view />,
    },
    {
      path: "/task_name",
      element: <Task_Name />,
    },
    {
      path: "/team_name",
      element: <Team_Name />,
    },
    {
      path: "/dashboard",
      element: <Dashbaordmain />,
    },
    {
      path: "/notification",
      element: <TabsNotify />,
    },
    {
      path: "/notification-compliance",
      element: <ComplianceNotif />,
    },
    {
      path:"/track-compliance",
      element:<ComplianceTracker/>
    },
    {
      path: "/company-list",
      element: <Company_tabs />,
    },
    {
      path: "/preference",
      element: <Prefernce/>,
    },

  ]);
}
