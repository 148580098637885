import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../Logo/loader.png";
import {
  Alert,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useTheme, styled } from "@mui/material/styles";
import moment from "moment";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { TextField, Grid } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage

const Chip_Lab = (props) => {
  const { value, open, HandlePopper, UpdateTable } = props;
  const [open_Dialog, setOpen_Dialog] = useState(false);
  const [FilingStatus, setFilingStatus] = useState();
  const [Remarks, setRemarks] = useState();
  const handleUpdateStatus = (e, val) => {
    const user_work = localStorage.getItem("email");
    if (FilingStatus === "In active") {
      console.log("Make it InACTIVE");
      var update_pref = JSON.stringify({
        compliance_id: value.compliance,
        state: value.state,
        is_active: false,
        start_month: moment(value.created_at).format("MM"),
        start_year: moment(value.created_at).format("YYYY"),
      });
      const apiUrl = `https://api.startupfino.in/internal_compliance/add_company_preference/${value?.company}/`;
      axios
        .post(apiUrl, update_pref, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log("Data posted successfully:", response.data);
          toast.success(`Marked ${value.company_name} As In Active`);
          setOpen_Dialog(false);
        });
    } else {
      const data = {
        status: FilingStatus,
        compliance_done_by: user_work || "email_not_found",
        remarks: Remarks,
      };
      const apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/${val}/`;
      axios
        .patch(apiUrl, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            // Replace with your actual access token
          },
        })
        .then((response) => {
          toast.success("Updated Filing Status");
          UpdateTable(val, FilingStatus);
          setOpen_Dialog(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <>
      <Chip
        label={value?.status}
        color={
          value?.status === "Pending"
            ? "error"
            : value?.status === "Delayed Filled"
            ? "error"
            : "success"
        }
        variant="outlined"
        onClick={() => setOpen_Dialog(!open_Dialog)}
        sx={{ cursor: "pointer" }}
      />
      {/* <h2>{value.status}</h2> */}
      <Dialog
        open={open_Dialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <b>Alert Filing Status Will be Updated </b>
            <CloseRounded
              onClick={() => setOpen_Dialog(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <Box
            sx={{
              margin: 1,
              justifyContent: "center",
              flexDirection: "column",
            }}
            display="flex"
          >
            <Alert
              severity={FilingStatus === "In active" ? "error" : "success"}
            >
              {FilingStatus === "In active" && (
                <>
                  <Typography variant="h4" fontSize="18px">
                    <b>Are Sure Marking as In Active?</b>
                  </Typography>
                  <br></br>
                </>
              )}
              <Typography>
                <b>Company Name</b> : {value.company_name}
              </Typography>
              <Typography>
                <b>Compliance</b> : {value.compliance_name}-{value.frequency}-
                {moment().month(value.month).format("MMM")}
              </Typography>
              <Autocomplete
                label="Status"
                fullWidth
                id="combo-box-demo"
                sx={{ mt: 2 }}
                value={FilingStatus}
                onChange={(e, newVal) => {
                  setFilingStatus(newVal);
                }}
                options={[
                  "Filled by Us",
                  "Filled by Client",
                  "Data Pending",
                  "Pending",
                  "Delayed Filled",
                  "In active",
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Compliance Status" />
                )}
              />
              <br />
              {FilingStatus === "Delayed Filled" && (
                <TextField
                  label="Remarks/Reason for Delay"
                  value={Remarks}
                  fullWidth
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              )}
            </Alert>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={(e) => {
              handleUpdateStatus(e, value.id);
            }}
          >
            Yes,I Confirm
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setopen(false);
            }}
          >
            No
          </Button>
        </DialogActions>
        <DialogContent dividers={scroll === "paper"}>
          <Box
            sx={{
              margin: 1,
              justifyContent: "center",
              flexDirection: "column",
            }}
            display="flex"
          ></Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
const EditPopBox = (props) => {
  const { data, UpdateTablePassword } = props;
  const [open, setopen] = useState(false);
  const [name, setname] = useState(data.username);
  const [pass, setpass] = useState(data.password);
  const handleOpen = () => {
    setopen(true);
  };
  const handleUpdatePass = () => {
    const updatedData = new FormData();
    updatedData.append("username", name);
    updatedData.append("password", pass);
    console.log("UPDATE", data.id, data.cred_id);
    const updatedConfig = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `https://api.startupfino.in/internal_compliance/company_credential/${data.cred_id}/`,
      headers: {
        Authorization: `Bearer ${accessToken}`, // Replace with your actual access token
      },
      data: updatedData,
    };

    axios
      .request(updatedConfig)
      .then((response) => {
        console.log("Password updated successfully:", response.data);
        toast.success("Updated ui / pass");
        UpdateTablePassword(data.id, name, pass);
        setopen(close);
        // Optionally, you can handle the updated data or close the dialog here
      })
      .catch((error) => {
        console.error("Error updating password:", error);
        // Handle the error as needed
      });
  };

  return (
    <>
      <EditIcon
        sx={{ color: "green", cursor: "pointer" }}
        onClick={() => setopen(true)}
      />
      <Dialog
        open={open}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <b>
              Update {data?.company_name} info {data?.id}
            </b>
            <CloseRounded
              onClick={() => setopen(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              margin: 1,
              justifyContent: "center",
              flexDirection: "column",
            }}
            display="flex"
          >
            <TextField
              value={name}
              label="Username"
              onChange={(e) => {
                setname(e.target.value);
              }}
              sx={{ m: 1 }}
            />
            <TextField
              value={pass}
              label="Password"
              onChange={(e) => {
                setpass(e.target.value);
              }}
              sx={{ m: 1 }}
            />
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            <Button
              variant="contained"
              onClick={() => {
                handleUpdatePass();
              }}
            >
              Update Values
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const GSTSection = (props) => {
  const { date, filing_filter, QuaterMonth, QuaterYear } = props;
  const [GST_Data, setGST_Data] = useState([]);
  const [Uid_Pass, setUid_Pass] = useState([]);
  const [MergedData, setMergedData] = useState([]);
  const [url_val, seturl_val] = useSearchParams();
  const category = url_val.get("category");
  const compliance = url_val.get("compliance");
  const freq = url_val.get("frequency");
  const month = url_val.get("month");
  const [rowsPerPage, setRowsPerPage] = useState(GST_Data?.length);

  const [value, setValue] = useState(() => {
    if ((compliance === "GSTR1") & (freq === "Monthly")) {
      return 0;
    }
    if ((compliance === "GSTR1") & (freq === "Quaterly")) {
      return 1;
    }
    if ((compliance === "GSTR3B") & (freq === "Monthly")) {
      return 2;
    }
    if ((compliance === "GSTR3B") & (freq === "Quaterly")) {
      return 3;
    }
    if ((compliance === "GSTR9") & (freq === "Annually")) {
      return 4;
    }
    if ((compliance === "GSTR9C") & (freq === "Annually")) {
      return 5;
    }
    if (localStorage.getItem("GstComp") !== null) {
      return parseInt(localStorage.getItem("GstComp"));
    } else {
      return 0;
    }
  });
  const nav = useNavigate();
  const [GST_TableData, setGstTableData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [UpdateIndexTable, setUpdateIndexTable] = useState();
  const [UpdateIndexTable_Status, setUpdateIndexTable_Status] = useState();
  const [Date_filing_Update, setDate_filing_Update] = useState();

  const UpdateTable = (idx, fs) => {
    setter(false);
    // Assuming GST_TableData is the original data array
    const updatedData = GST_TableData.map((item) => {
      if (item.status.id === idx) {
        // Update the fields you need to update
        return {
          ...item,
          status: {
            ...item,
            status: fs,
            date_of_filing: moment().format("DD-MM-YY"),
          },
          date_of_filing: moment().format("DD-MM-YY"),
        };
      }
      return item;
    });

    // Set the state with the updated data
    setGstTableData(updatedData);
  };
  const UpdateTablePassword = (idx, name, pass) => {
    setter(false);
    console.log("livingFFuntion", GST_TableData);
    // Assuming GST_TableData is the original data array
    const updatedData = GST_TableData.map((item) => {
      console.log("itessssssss", idx);
      if (item.uid.id === idx) {
        // Update the fields you need to update
        return {
          ...item,
          uid: {
            ...item,
            username: name,
          },
          password: {
            ...item,
            password: pass,
          },
        };
      }
      return item;
    });

    console.log("newData", updatedData);
    // Set the state with the updated data
    setGstTableData(updatedData);
  };
  const HandlePopper = () => {
    setOpen(true);
  };

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    rowsPerPage: GST_Data?.length,
    rowsPerPageOptions: [50, 100, 500, 1000, 2000],
    searchAlwaysOpen: true,
  };

  useEffect(() => {
    setLoading(true);
    let apiUrl = "";
    let UserIdUrl =
      "https://api.startupfino.in/internal_compliance/company_credential/?category=GST";
    if (value == 0) {
      if (filing_filter !== null) {
        apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=1&month=${moment(
          date
        )
          .subtract(1, "month")
          .format("MM")}&year=${moment(date)
          .subtract(1, "month")
          .format("YYYY")}&status=${filing_filter}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR1&frequency=Monthly&Month=${moment(
            date
          )
            .subtract(1, "month")
            .format("MM")}&filing=${filing_filter}`
        );
      } else {
        apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=1&month=${moment(
          date
        )
          .subtract(1, "month")
          .format("MM")}&year=${moment(date)
          .subtract(1, "month")
          .format("YYYY")}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR1&frequency=Monthly&Month=${moment(
            date
          )
            .subtract(1, "month")
            .format("MM")}`
        );
      }

      localStorage.setItem("GstComp", 0);
    }
    if (value == 1) {
      if (QuaterMonth != null) {
        if (filing_filter !== null) {
          apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=3&month=${moment(
            QuaterMonth
          ).format("MM")}&year=${moment(QuaterYear)}&status=${filing_filter}`;
          nav(
            `/track-compliance?category=GST&compliance=GSTR1&frequency=Quaterly&Month=${moment(
              QuaterMonth
            ).format("MM")}&QY=${QuaterYear}&status=${filing_filter}`
          );
        } else {
          apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=3&month=${moment(
            QuaterMonth
          ).format("MM")}&year=${QuaterYear}`;
          nav(
            `/track-compliance?category=GST&compliance=GSTR1&frequency=Quaterly&Month=${moment(
              QuaterMonth
            ).format("MM")}&QY=${QuaterYear}`
          );
        }
      } else {
        nav(
          `/track-compliance?category=GST&compliance=GSTR1&frequency=Quaterly&Month=${moment(
            QuaterMonth
          ).format("MM")}&QY=${QuaterYear}`
        );
      }

      localStorage.setItem("GstComp", 1);
    }
    if (value == 2) {
      if (filing_filter !== null) {
        apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=2&month=${moment(
          date
        )
          .subtract(1, "month")
          .format("MM")}&year=${moment(date)
          .subtract(1, "month")
          .format("YYYY")}&status=${filing_filter}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR3B&frequency=Monthly&Month=${moment(
            date
          )
            .subtract(1, "month")
            .format("MM")}&status=${filing_filter}`
        );
      } else {
        apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=2&month=${moment(
          date
        )
          .subtract(1, "month")
          .format("MM")}&year=${moment(date)
          .subtract(1, "month")
          .format("YYYY")}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR3B&frequency=Monthly&Month=${moment(
            date
          )
            .subtract(1, "month")
            .format("MM")}`
        );
      }

      localStorage.setItem("GstComp", 2);
    }
    if (value == 3) {
      if (QuaterMonth) {
        if (filing_filter !== null) {
          apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=4&month=${moment(
            QuaterMonth
          ).format("MM")}&year=${moment(QuaterMonth)
            .subtract(1, "month")
            .format("YYYY")}&status=${filing_filter}`;
          nav(
            `/track-compliance?category=GST&compliance=GSTR3B&frequency=Quaterly&Month=${moment(
              QuaterMonth
            )
              .subtract(1, "month")
              .format("MM")}&status=${filing_filter}`
          );
        } else {
          apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=4&month=${moment(
            QuaterMonth
          ).format("MM")}&year=${moment(QuaterMonth)
            .subtract(1, "month")
            .format("YYYY")}`;
          nav(
            `/track-compliance?category=GST&compliance=GSTR3B&frequency=Quaterly&Month=${moment(
              QuaterMonth
            ).format("MM")}`
          );
        }
      } else {
        nav(
          `/track-compliance?category=GST&compliance=GSTR3B&frequency=Quaterly&Month=${moment(
            QuaterMonth
          ).format("MM")}`
        );
      }

      localStorage.setItem("GstComp", 3);
    }
    if (value == 4) {
      if (filing_filter !== null) {
        // apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=4&month=${moment(
        //   QuaterMonth
        // ).format("MM")}&year=${moment(QuaterMonth).format(
        //   "YYYY"
        // )}&status=${filing_filter}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR9&frequency=Annually`
        );
      } else {
        // apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=4&month=${moment(
        //   QuaterMonth
        // ).format("MM")}&year=${moment(QuaterMonth).format("YYYY")}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR9&frequency=Annually`
        );
      }
    }
    if (value == 5) {
      if (filing_filter !== null) {
        // apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=4&month=${moment(
        //   QuaterMonth
        // ).format("MM")}&year=${moment(QuaterMonth).format(
        //   "YYYY"
        // )}&status=${filing_filter}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR9C&frequency=Annually`
        );
      } else {
        // apiUrl = `https://api.startupfino.in/internal_compliance/selected_compliances/?compliance=4&month=${moment(
        //   QuaterMonth
        // ).format("MM")}&year=${moment(QuaterMonth).format("YYYY")}`;
        nav(
          `/track-compliance?category=GST&compliance=GSTR9C&frequency=Annually`
        );
      }

      localStorage.setItem("GstComp", 3);
    }
    if (
      (value == 3 || value == 1) &&
      QuaterMonth === null &&
      moment(QuaterMonth).format("MM") != "01" &&
      moment(QuaterMonth).format("MM") != "10" &&
      moment(QuaterMonth).format("MM") != "04"
    ) {
      toast.error("Please Select a Quater To View Data");
    } else {
      axios
        .get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          setGST_Data(res.data);
          console.log("QuaterMonth", moment(QuaterMonth).format("MM"));
        });
    }

    axios
      .get(UserIdUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        setUid_Pass(res.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000); // 3000 milliseconds = 3 seconds
      });
  }, [value, date, filing_filter, QuaterMonth, QuaterYear]);

  const handleChange = (event, newValue) => {
    setGST_Data(null);
    setValue(newValue);
  };
  const updateDataWithCredentials = () => {
    const updatedData = GST_Data?.map((data) => {
      const matchCredentials = Uid_Pass.find(
        (cred) => data.company === cred.company && data.state === cred.state
      );

      if (matchCredentials) {
        return {
          ...data,
          username: matchCredentials.username,
          password: matchCredentials.password,
          compliance_number: matchCredentials.compliance_number,
          cred_id: matchCredentials.id,
        };
      }

      return data;
    });
    setMergedData(updatedData);
  };
  const MapFunction = () => {
    const tab_map_data = MergedData?.map((item) => {
      return {
        update: item,
        name: item.company_name,
        compliance_name: `${item.compliance_name}-${
          item.frequency === "Monthly" ? "MON" : "QTR"
        }`,
        frequency: item.frequency,
        state: item.state,
        id: item.id,
        month: moment().month(item.month).format("MMMM"),
        date_of_filing:
          item.date_of_filing === null
            ? "N/A"
            : moment(item.date_of_filing).format("DD-MM-YY"),
        status: item,
        filedby:
          item.compliance_done_by === null ? "None" : item.compliance_done_by,
        uid: item,
        password: item,
        compliance_number: item.compliance_number,
        cred_id: item.id,
      };
    });
    setGstTableData(tab_map_data);
  };
  const [copied, setCopied] = useState(false);

  const HandleCopytoClip = (text, rowIndex) => {
    navigator.clipboard
      .writeText(text)
      .then(() => console.log("copiedText", text));

    console.log(
      "navigator.clipboard.writeText(text);",
      navigator.clipboard.writeText(text)
    );
    setCopied(true);
    setTimeout(() => setCopied(false), 10000);
  };
  const [est, setter] = useState(true);
  useEffect(() => {
    updateDataWithCredentials();
    MapFunction();
  }, [GST_Data, Uid_Pass]);
  useEffect(() => {
    if (est) {
      updateDataWithCredentials();
      MapFunction();
    }
  });
  const columns = [
    {
      name: "update",
      label: "Update",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <EditPopBox data={data} UpdateTablePassword={UpdateTablePassword} />
          );
        },
      },
    },
    {
      name: "name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "compliance_name",
      label: "Compliance",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "credid",
      label: "credid",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "compliance_number",
      label: "Compliance No.",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "uid",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <Box display={"flex"} flexDirection={"row"} justifyItems={"center"}>
              <IconButton
                sx={{ mr: 2, cursor: "pointer" }}
                onClick={() => {
                  HandleCopytoClip(data.username, rowIndex);
                }}
              >
                {copied ? <ContentCopyIcon /> : <ContentCopyIcon />}
                <Typography>{data.username}</Typography>
              </IconButton>
            </Box>
          );
        },
      },
    },
    {
      name: "password",
      label: "Password",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <Box display={"flex"} flexDirection={"row"} justifyItems={"center"}>
              <IconButton
                sx={{ mr: 2, cursor: "pointer" }}
                onClick={() => {
                  HandleCopytoClip(data.password, rowIndex);
                }}
              >
                {copied ? <ContentCopyIcon /> : <ContentCopyIcon />}
                <Typography>{data.password}</Typography>
              </IconButton>
            </Box>
          );
        },
      },
    },
    {
      name: "date_of_filing",
      label: "Date of Filing",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <Chip_Lab
              value={data}
              open={open}
              HandlePopper={HandlePopper}
              UpdateTable={UpdateTable}
            />
          );
        },
      },
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ marginTop: 2 }}
        >
          <Tab label="GST-R1 Monthly" {...a11yProps(0)} />
          <Tab label="GST-R1 Quaterly" {...a11yProps(1)} />
          <Tab label="GST-3B Monthly" {...a11yProps(2)} />
          <Tab label="GST-3B Quaterly" {...a11yProps(3)} />
          <Tab label="GST-9" {...a11yProps(4)} />
          <Tab label="GST-9C" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {Loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "70vh", position: "relative" }}
          >
            <Grid item sx={{ position: "absolute", zIndex: 2 }}>
              <CircularProgress color="success" size={90} />
            </Grid>
            <Grid item sx={{ position: "absolute", zIndex: 1 }}>
              <img src={logo} alt="Logo" />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <MUIDataTable
          options={options}
          title={"GSTR-1"}
          columns={columns}
          data={GST_TableData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {Loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "70vh", position: "relative" }}
          >
            <Grid item sx={{ position: "absolute", zIndex: 2 }}>
              <CircularProgress color="success" size={90} />
            </Grid>
            <Grid item sx={{ position: "absolute", zIndex: 1 }}>
              <img src={logo} alt="Logo" />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <MUIDataTable
          options={options}
          title={"GSTR-1"}
          columns={columns}
          data={GST_TableData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {Loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "70vh", position: "relative" }}
          >
            <Grid item sx={{ position: "absolute", zIndex: 2 }}>
              <CircularProgress color="success" size={90} />
            </Grid>
            <Grid item sx={{ position: "absolute", zIndex: 1 }}>
              <img src={logo} alt="Logo" />
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <MUIDataTable
          options={options}
          title={"GSTR-1"}
          columns={columns}
          data={GST_TableData}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {Loading ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "70vh", position: "relative" }}
          >
            <Grid item sx={{ position: "absolute", zIndex: 2 }}>
              <CircularProgress color="success" size={90} />
            </Grid>
            <Grid item sx={{ position: "absolute", zIndex: 1 }}>
              <img src={logo} alt="Logo" />
            </Grid>
          </Grid>
        ) : (
          <MUIDataTable
            options={options}
            title={"GSTR-1"}
            columns={columns}
            data={GST_TableData}
          />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        GST-9
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        GST-9C
      </CustomTabPanel>
    </Box>
  );
};

export default GSTSection;
