import React from "react";
import { Line } from "react-chartjs-2";
import {
  PointElement,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart as ChartJS,
} from "chart.js";
import moment from "moment";
import { Typography } from "@mui/material";
ChartJS.register(
  PointElement,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};
//1st month filter

const LineChart = (props) => {
  const { datasets } = props;
  let arrayss = [];
  arrayss = console.log(
    "rrrrrrrr",
    datasets?.map((item) => item.completed_task)
  );
  var lineardata = datasets?.map((item) => item.completed_task);
  var lineardata2 = datasets?.map((item) => item.created_task);
  console.log("hi",lineardata,"Create",lineardata2)
  

  const data = {
    labels: [
      moment().subtract(6, "month").format("MMM"),
      moment().subtract(5, "month").format("MMM"),
      moment().subtract(4, "month").format("MMM"),
      moment().subtract(3, "month").format("MMM"),
      moment().subtract(2, "month").format("MMM"),
      moment().subtract(1, "month").format("MMM"),
    ],  
    datasets: [
      {
        label: "Completed",
        data: lineardata,
        borderColor: "#00cc00",
      },
      {
        label: "Created",
        data: lineardata2,
        borderColor: "#0000ff",
      },
    ],
  };
  return (
    <div>
      <Typography fontSize="20px" sx={{mt:2,ml:2}}>
        Monthly Completed Task
      </Typography>

      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
