import React, { useState, useEffect } from "react";
import { Grid, Autocomplete, TextField, Card, Button } from "@mui/material";
import Box from "@mui/material/Box";
import ClippedDrawer from "../sidebar/sidebar_list";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import GSTSection from "./GSTSection";
import TDSSection from "./TDSSection";
import ITSection from "./ITSection";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PF_module from "./PF/PF_module";

const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage

const ComplianceTracker = () => {
  const nav = useNavigate();
  const [Gst_open, setGst_open] = useState(false);
  const [Quaters, setQuaters] = useState(null);
  const [QuaterMonth, setQuaterMonth] = useState(null);
  const [QuaterYear, setQuaterYear] = useState(moment().format("YYYY"));

  const [TDS_open, setTDS_open] = useState(false);
  const [IT_open, setIT_open] = useState(false);
  const [ROC_open, setROC_open] = useState(false);
  const [PF_open, setPF_open] = useState(false);
  const [ESIC_open, setESIC_open] = useState(false);
  const [filing_filter, setfiling_filter] = useState(null);
  const [date, setDate] = useState(moment());
  const [url_val, seturl_val] = useSearchParams();
  const category = url_val.get("category");
  const quater = url_val.get("frequency");
  console.log("category");
  let opener = "GSTCLOSE";
  let damshit = "GSTOPEN";
  useEffect(() => {
    console.log("localStorage.getItem", localStorage.getItem("OpenTAB"));
    if (
      category === "GST" && localStorage.getItem("OpenTAB") === "GSTOPEN"
        ? true
        : false
    ) {
      setPF_open(false);
      setGst_open(true);
    }
    if (
      category === "PF" && localStorage.getItem("OpenTAB") === "PF"
        ? true
        : false
    ) {
      setGst_open(false);
      setPF_open(true);
      //  nav("/track-compliance?category=PF")
    }
  }, [localStorage.getItem("OpenTAB")]);
  const handleGSTPortalData = () => {
    const url = `https://api.startupfino.in/internal_compliance/fetch_gst_data_from_portal/?month=${moment(
      date
    )
      .subtract(1, "month")
      .format("MM")}&year=${moment(date).subtract(1, "month").format("YYYY")}`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    console.log("url", url);
    axios
      .get(url, config)
      .then((response) => {
        console.log(response.data);
        // Trigger toast on successful response
        toast.success("Triggered successfully");
      })
      .catch((error) => {
        console.error(error);
        // Trigger toast on error
        toast.error("Error triggering GST portal");
      });
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <ClippedDrawer />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pr: 3,
            pt: 2,
            pl: 2,
            width: "100%",
            overflowX: "auto",
          }}
        >
          <Grid container>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => {
                  localStorage.setItem("OpenTAB", "GSTOpen");
                  console.log(
                    "localStorage.getItemCLICKED",
                    localStorage.getItem("OpenTAB")
                  );
                  setGst_open(true);
                }}
              >
                GST
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => setTDS_open(true)}
              >
                TDS
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => setIT_open(true)}
              >
                Income Tax
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => setROC_open(true)}
              >
                ROC
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => {
                  setPF_open(true);
                  nav("/track-compliance?category=PF&frequency=monthly");
                  localStorage.setItem("OpenTAB", "PF");
                }}
              >
                PF
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                variant="contained"
                size="large"
                onClick={(e) => setESIC_open(true)}
              >
                ESIC
              </Button>
            </Grid>
          </Grid>
          {Gst_open && (
            <>
              <h4>GST Compliances</h4>
              <Grid container>
                <Grid item xs={4}>
                  {quater === "Quaterly" ? (
                    <>
                      <Grid container>
                        <Grid item xs={6}>
                          <Autocomplete
                            label="Quaters"
                            id="combo-box-demo"
                            value={Quaters}
                            sx={{ mr: 2 }}
                            onChange={(e, newVal) => {
                              if (newVal === "Q1-April-June") {
                                setQuaterMonth(moment().month(3));
                              }
                              if (newVal === "Q2-July-September") {
                                setQuaterMonth(moment().month(9));
                              }
                              if (newVal === "Q3-Octember-December") {
                                setQuaterMonth(
                                  moment().month(0).add(1, "year")
                                );
                              }
                              if (newVal === "Q4-January-March") {
                                setQuaterMonth(
                                  moment().month(3).add(1, "year")
                                );
                              }
                              setQuaters(newVal);
                            }}
                            options={[
                              "Q1-April-June",
                              "Q2-July-September",
                              "Q3-Octember-December",
                              "Q4-January-March",
                            ]}
                            renderInput={(params) => (
                              <TextField {...params} label="Quater" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              views={["year"]}
                              value={moment(QuaterYear)}
                              sx={{ mr: 2 }}
                              onChange={(newDate) => {
                                setQuaterYear(moment(newDate).format("YYYY"));
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          views={["month", "year"]}
                          value={moment(date).subtract(1, "month")}
                          sx={{ mr: 2 }}
                          onChange={(newDate) => {
                            let d2 = moment(newDate).add(1, "month");
                            setDate(d2);
                          }}
                        />
                      </LocalizationProvider>
                    </>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Autocomplete
                    label="Filing Status"
                    id="combo-box-demo"
                    value={filing_filter}
                    onChange={(e, newVal) => {
                      setfiling_filter(newVal);
                    }}
                    options={[
                      "Filled",
                      "Filled by Us",
                      "Filled by Client",
                      "Data Pending",
                      "Pending",
                      "Delayed Filled",
                    ]}
                    renderInput={(params) => (
                      <TextField {...params} label="Filing Status" />
                    )}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <Button
                    sx={{ mt: 1.5 }}
                    variant="contained"
                    onClick={(e) => handleGSTPortalData()}
                  >
                    Get GST data From Portal
                  </Button>
                </Grid>
              </Grid>
              <GSTSection
                date={date}
                filing_filter={filing_filter}
                QuaterMonth={QuaterMonth}
                QuaterYear={QuaterYear}
              />
            </>
          )}
          {TDS_open && (
            <>
              <h4>TDS Compliances</h4>
              <Grid container>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker views={["month", "year"]} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    label="Filing Status"
                    id="combo-box-demo"
                    // value={company_type}
                    // onChange={(e, newVal) => {
                    //   Setcompany_type(newVal);
                    //   formData.company_type = newVal;
                    // }}
                    options={["Pending", "Filed"]}
                    renderInput={(params) => (
                      <TextField {...params} label="Filing Status" />
                    )}
                  />
                </Grid>
              </Grid>
              <TDSSection />
            </>
          )}
          {IT_open && (
            <>
              <h4>IT Compliances</h4>
              <Grid container>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker views={["month", "year"]} />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    label="Filing Status"
                    id="combo-box-demo"
                    // value={company_type}
                    // onChange={(e, newVal) => {
                    //   Setcompany_type(newVal);
                    //   formData.company_type = newVal;
                    // }}
                    options={["Pending", "Filed"]}
                    renderInput={(params) => (
                      <TextField {...params} label="Filing Status" />
                    )}
                  />
                </Grid>
              </Grid>
              <ITSection />
            </>
          )}{" "}
          {PF_open && (
            <>
              <h4>PF</h4>
              <Grid container>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker views={["month", "year"]} />
                  </LocalizationProvider>
                </Grid>
                <PF_module />
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ComplianceTracker;
