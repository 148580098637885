import React from "react";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
// https://api.startupfino.in/company/all-companies/
import axios from "axios";
import { useEffect } from "react";
import { Chip, Box, TableRow } from "@mui/material";
import { Typography } from "@mui/material";
import moment from "moment";
import Add_task from "../Task_addition/task_Add";
import logo from "../../../src/Logo/startupfino.png";
import ClippedDrawer from "../../sidebar/sidebar_list";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Task_Add from "../Task_addition/task_Add";
import Taskupdate from "../task_edit/task_update";
import { useConfirm } from "material-ui-confirm";
import { ConfirmProvider } from "material-ui-confirm";
import TaskDeletes from "../taskDelete/TaskDeletes";
import { Tooltip } from "@mui/material";
import { CircularProgress } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import {
  AppBar,
  Toolbar,
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import { Button } from "@mui/material";
import logos from "../../../src/Logo/loader.png";
import { toast } from "react-toastify";
import Loader from "../../loader/loader";

const Task_view = () => {
  const [opene, setOpene] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [all_companies, setall_companies] = useState([]);
  const [all_task_name, setall_task_name] = useState([]);
  const [all_team_members, setall_team_members] = useState([]);
  const [client_mails, setClient_mails] = useState([]);
  const [signedInMate, setSignedSignMate] = useState("");
  const [previousScroll, setPreviousScroll] = useState(0);
  const userId = localStorage.getItem("user_id");
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(false);
  const [page, setpage] = useState(0);
  const [completed, setcompleted] = useState([]);
  const [Cancelled, setcancelled] = useState([]);

  const handleClickedStatus = (e, status) => {
    e.preventDefault();
    console.log("status", status);
    setClick(true);
  };
  const logged_user = localStorage.getItem("user_id");
  console.log("loggedUser", logged_user);
  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpene(false);
  };
  const columns = [
    {
      name: "is_reccuring",
      label: "is_reccuring",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "update",
      label: "Update/Delete",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box textAlign="center">
                <Update
                  row={data}
                  company_name={opts}
                  task_names={tsk_name_opts}
                  teamates_data={team_mem_opts}
                  client_mail_list={client_mail_opts}
                  all_companies={all_companies}
                  all_mail={client_mails}
                  all_task_name={all_task_name}
                  all_team_members={all_team_members}
                  all_created_Task={datas?.results}
                  handleUpdate={handleUpdate}
                />
                <Delete
                  row={data}
                  company_name={opts}
                  task_names={tsk_name_opts}
                  teamates_data={team_mem_opts}
                  client_mail_list={client_mail_opts}
                  all_companies={all_companies}
                  all_mail={client_mails}
                  all_task_name={all_task_name}
                  all_team_members={all_team_members}
                  all_created_Task={datas?.results}
                  handleUpdate={handleUpdate}
                />
                <Task_File row={data} all_created_Task={datas?.results} />
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "task_id",
      label: "T-ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "task_name",
      label: "Task",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "task_Desc",
      label: "Task Desc",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data, dataIndex, rowIndex) => {
          // Split the task description into an array of words
          const wordsArray = data.split(/\s+/);
          // Take the first three words from the array
          const firstThreeWords = wordsArray.slice(0, 3);
          // Join the first three words back into a single string
          const limitedDescription = firstThreeWords.join(" ");
          // Append "..." if the original description has more than three words
          const displayedDescription =
            wordsArray.length > 3
              ? `${limitedDescription} ...`
              : limitedDescription;

          // Use Tooltip to show the full task description as a tooltip
          return (
            <Tooltip title={data}>
              <span>{displayedDescription}</span>
            </Tooltip>
          );
        },
      },
    },

    {
      name: "task_assn",
      label: "TA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "due_date",
      label: "Due Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status_task",
      label: "Status",
      setCellProps: () => ({
        style: {
          whiteSpace: "wrap",
        },
      }),
      options: {
        filter: true,
        sort: true,
        filterList: [
          "Initiated",
          "In the Process",
          "Not Started",
          "Data is Pending from Client",
        ],

        customBodyRender: (data, rowData, dataIndex) => {
          // console.log("meta",rowData,dataIndex)
          return (
            <>
              <Task_Status_Change
                data={data}
                rowData={rowData}
                handleUpdate={handleUpdate}
              />
            </>
          );
        },
      },
    },
    {
      name: "task_own",
      label: "TO",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "date_task",
      label: "Date of Task",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "reccuring_period",
      label: "Recurring Period",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bill_info",
      label: "Billing Info",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bill_amt",
      label: "Biling Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "invoice_num",
      label: "Invoice ID",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  // wrte code for api call using axios
  const [datas, setData] = useState([]);
  const [table_datas, settable_Data] = useState([]);

  const handleUpdate = () => {
    setLoading(true);
    axios
      .get(
        `https://api.startupfino.in/task-manager/api/task-management/?page=${
          page + 1
        }&page_size=50&task_status__in=Initiated,In the Process,Not Started,Data is Pending from Client,Completed`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
        window.scrollTo(0, previousScroll);
      });
  };
  console.log("prev", previousScroll);

  useEffect(() => {
    setPreviousScroll(window.scrollY);
    setLoading(true);
    const fetchData = async () => {
      const result = await axios(
        `https://api.startupfino.in/task-manager/api/task-management/?page=${
          page + 1
        }&page_size=50&task_status__in=Initiated,In the Process,Not Started,Data is Pending from Client,Completed`
      );
      setData(result.data);
      const completed = await axios(
        `https://api.startupfino.in/task-manager/api/task-management/?page=${
          page + 1
        }&page_size=2&task_status__in=Completed`
      );
      setcompleted(completed.data.count);
      const Cancelled = await axios(
        `https://api.startupfino.in/task-manager/api/task-management/?page=${
          page + 1
        }&page_size=2&task_status__in=Initiated,In the Process,Not Started,Data is Pending from Client`
      );
      setcancelled(Cancelled.data.count);

      const companies = await axios(
        "https://api.startupfino.in/company/all-companies/"
      );

      setall_companies(companies.data);
      const task_names = await axios(
        "https://api.startupfino.in/task-manager/api/task-name/"
      );

      setall_task_name(task_names.data);
      const team_members = await axios(
        "https://api.startupfino.in/task-manager/api/team-mates/"
      );

      setall_team_members(team_members.data);
      const client_mail_lists = await axios(
        "https://api.startupfino.in/task-manager/api/client-mail/"
      );
      setClient_mails(client_mail_lists.data);
      setLoading(false);
    };
    fetchData();
  }, [page]);

  useEffect(() => {
    if (all_team_members.length > 0) {
      const selectUser = all_team_members.filter((item) => {
        return item.user_id === parseInt(userId);
      });
      if (selectUser.length > 0) {
        axios
          .get(
            `https://api.startupfino.in/task-manager/api/task-management/?page=${
              page + 1
            }&page_size=50&task_status__in=Initiated,In the Process,Not Started,Data is Pending from Client,Completed&task_assignee_id=${
              selectUser[0].id
            }`
          )
          .then((res) => {
            setData(res.data);
          });
      }
      if (selectUser.length === 0 && userId != 402) {
        setData([]);
      }
    }
  }, [all_team_members, datas?.results]);
  const opts = all_companies?.map((option) => option.company_name);
  const tsk_name_opts = all_task_name.map((option) => option.task_name);
  const team_mem_opts = all_team_members.map((option) => option.name);
  const client_mail_opts = client_mails.map((option) => option.client_mail);

  console.log("client_mail_opts", client_mail_opts);

  console.log("all companies", all_companies);

  //write code for maping data from api to table data
  useEffect(() => {
    const data = datas?.results?.map((row) => {
      return {
        is_reccuring: row.is_recurring,
        task_id: row.sr_id,
        name: row.company_name,
        task_name: row.task_name,
        task_Desc: row.task_description,
        task_own: row.task_owner,
        task_assn: row.task_assignee,
        date_task: moment(row.date_of_task).format("DD-MM-YYYY"),
        due_date: moment(row.due_date).format("DD-MM-YYYY"),
        bill_info: row.billing_info,
        bill_amt: row.billing_amount,
        status_task: row.task_status,
        remarks: row.remark,
        update: row.id,
        invoice_num: row.invoice_num,
        reccuring_period: row.recurring_period,
      };
    });
    settable_Data(data);
  }, [page, datas?.results]);

  const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    {
      name: "James Houston",
      company: "Test Corp",
      city: "Dallas",
      state: "TX",
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    rowsPerPageOptions: [50, 100, 1000, 1500, 2000],
    rowsPerPage: 50,
    responsive: "standard",
    fixedSelectColumn: true,
    tablebodywidth: "auto",
    count: datas?.count,
    setRowProps: (row, dataIndex, rowIndex) => {
      console.log(row[0], "row");
      return {
        style: { background: row[0] === true ? "#80808052" : "" },
      };
    },
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setpage(page + 1);
      }
    },
  };
  let comp_task = datas?.results?.filter((x) =>
    x.task_status.includes("Completed")
  );
  let pend_task = datas?.results?.filter(
    (x) => !x.task_status.includes("Cancelled")
  );
  console.log("datas", datas?.results);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <ClippedDrawer />
        {loading ? (
          <Loader />
        ) : (
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pr: 3,
              pt: 2,
              pl: 2,
              width: "100%",
              overflowX: "auto",
            }}
          >
            <Button
              variant="contained"
              style={{ background: "#229A16" }}
              onClick={handleClickOpene("paper")}
            >
              Add Task
            </Button>
            <Dialog
              open={opene}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              fullWidth={true}
            >
              <DialogTitle id="scroll-dialog-title">
                <Box
                  component="span"
                  m={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  Add Task
                  <CloseRoundedIcon
                    onClick={() => setOpene(false)}
                    style={{ color: "#ff5252" }}
                    boxShadow={1}
                  />
                </Box>
              </DialogTitle>
              <DialogContent dividers={scroll === "paper"}>
                <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                  <Task_Add
                    handleClosee={handleClosee}
                    company_name={opts}
                    task_names={tsk_name_opts}
                    teamates_data={team_mem_opts}
                    client_mail_list={client_mail_opts}
                    all_companies={all_companies}
                    all_mail={client_mails}
                    all_task_name={all_task_name}
                    all_team_members={all_team_members}
                    handleUpdate={handleUpdate}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>

            <Box>
              {" "}
              {/* Set overflow-x to 'auto' */}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  {/* <h4>Total Number of task = {dummy_task_data.length}</h4> */}
                  <Typography variant="h6" gutterBottom>
                    Total Number of task: {datas?.count}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom>
                    Completed task: {completed}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" gutterBottom>
                    Pending task: {Cancelled}
                  </Typography>
                </Grid>
              </Grid>
              <MUIDataTable
                title={"StartupFino Task"}
                data={table_datas}
                columns={columns}
                options={options}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Task_view;
const Update = (props) => {
  const {
    company_name,
    task_names,
    teamates_data,
    client_mail_list,
    all_companies,
    all_mail,
    all_task_name,
    all_team_members,
    row,
    all_created_Task,
    handleUpdate,
  } = props;
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen("paper")}
        aria-label="delete"
        color="primary"
      >
        <ModeEditIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Edit Task
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Taskupdate
              row={row} // row data
              handleClosee={handleClosee} // close dialog
              company_name={company_name} //Company name list Mapped value
              task_names={task_names} //task name list Mapped value
              teamates_data={teamates_data} //task name list Mapped value
              client_mail_list={client_mail_list} //Client name list Mapped value
              all_companies={all_companies} // all Company name list
              all_mail={all_mail} // all mail list list
              all_task_name={all_task_name} // all task name list
              all_team_members={all_team_members} // all team members list
              all_created_Task={all_created_Task} // all created task list
              handleUpdate={handleUpdate} // update function
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const Delete = (props) => {
  const { row, all_created_Task, handleUpdate } = props;
  const selected_task = all_created_Task?.filter((item) => item.id === row)[0];
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        onClick={handleClickOpen("paper")}
        color="error"
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"md"}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Delete Task
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TaskDeletes
              row={row}
              handleClosee={handleClosee}
              selected_task={selected_task}
              handleUpdate={handleUpdate}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
const Task_File = (props) => {
  const { row, all_created_Task, handleUpdate } = props;
  const selected_task = all_created_Task?.filter((item) => item.id === row)[0];

  return (
    <>
      <IconButton
        aria-label="delete"
        color={selected_task?.attachment === null ? "" : "success"}
      >
        <TaskIcon
          disabled={selected_task?.attachment === null ? true : false}
          onClick={() => {
            window.open(selected_task?.attachment);
          }}
        />
      </IconButton>
    </>
  );
};
const Task_Status_Change = (props) => {
  const { data, rowData, handleUpdate } = props;
  // console.log("Data",data,"id",rowData?.rowData[2])

  return (
    <>
      <Autocomplete
        name="task_company_name"
        id="controllable-states-demo"
        value={data}
        label="Task Status"
        sx={{
          font: "white",
          minWidth: "190px",
          m: -1,
          backgroundColor:
            data === "Initiated"
              ? "#0288D1"
              : data === "In the Process"
              ? "#19857B"
              : data === "Not Started"
              ? "#3d4040"
              : data === "Data is Pending from Client"
              ? "#FFA500"
              : data === "Completed"
              ? "#2E7D32"
              : "#FF1744",
          borderRadius: "50px",
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(event, newValue) => {
          const updateData = {
            task_status: newValue,
          };
          axios
            .patch(
              `https://api.startupfino.in/task-manager/api/task-management/${rowData.rowData[2]}/`,
              updateData
            )
            .then((res) => {
              console.log("res", res);
              toast.success(
                `Task Id: ${rowData.rowData[2]} status updated successfully`
              );
              handleUpdate();
            });
        }}
        options={[
          "Initiated",
          "In the Process",
          "Not Started",
          "Data is Pending from Client",
          "Completed",
          "Cancelled",
        ]}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
              "& .MuiAutocomplete-inputRoot": {
                color: "white",
              },
            }}
          />
        )}
      />
    </>
  );
};
