import React from "react";
import MUIDataTable from "mui-datatables";
import { useState,useEffect } from "react";

const Emp_Task_table = (props) => {

  const {datasets,handleTop1,handleTop2,handleTop3}=props;
  const [tabledata,setData]=useState([]);
  const groupedData = {};
  console.log("employee",datasets)

useEffect(() => {
  const mappedArray = datasets?.map((item)=>{
    return{
      company_name:item.task_assignee,
      total:item.completed_company_task+item.not_started_company_task+item.initialised_company_task+item.in_the_process_company_task+item.pending_company_task,
      completed:item.completed_company_task,
      notstarted:item.not_started_company_task,
      initiated:item.initialised_company_task,
      intheprocess:item.in_the_process_company_task,
      dataispending:item.pending_company_task,
      ratio: ((item.completed_company_task / (item.completed_company_task+item.not_started_company_task+item.initialised_company_task+item.in_the_process_company_task+item.pending_company_task)) * 100).toFixed(2) + '%',

    }
  })
  setData(mappedArray);


}, [datasets])


const mappedArray2= datasets?.map((item)=>{
  return{
    company_name:item.task_assignee,
    total:item.completed_company_task+item.not_started_company_task+item.initialised_company_task+item.in_the_process_company_task+item.pending_company_task,
    completed:item.completed_company_task,
    notstarted:item.not_started_company_task,
    initiated:item.initialised_company_task,
    intheprocess:item.in_the_process_company_task,
    dataispending:item.pending_company_task,
    ratio: ((item.completed_company_task / item.completed_company_task+item.not_started_company_task+item.initialised_company_task+item.in_the_process_company_task+item.pending_company_task) * 100).toFixed(2) + '%',
  }
})
  .sort((a, b) => parseFloat(b.completed) - parseFloat(a.completed)) // Sort in descending order based on ratio
  .slice(0, 3); // Get the top 3 companies


console.log("top3perform",mappedArray2);
const columns = [
  {
    name: "company_name",
    label: "Employee",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "ratio",
    label: "Ratio%",
    options: {
      filter: true,
      sort: true,
    }
  },

  {
    name: "total",
    label: "Total",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "completed",
    label: "Completed",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "notstarted",
    label: "Not Started",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "initiated",
    label: "Initiated",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "intheprocess",
    label: "In Process",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "dataispending",
    label: "Data is Pending from Client",
    options: {
      filter: true,
      sort: true,
    },
  },

];



const options = {
  filterType: "multiselect",
  selectableRows: false,
  rowsPerPageOptions: [10, 50, 1000, 1500, 2000],
  rowsPerPage: 50,
  responsive: "standard",
  fixedSelectColumn: true,
  tablebodywidth: "auto",
};
  return (
    <MUIDataTable
      title={"Employee wise Task List"}
      data={tabledata}
      columns={columns}
      options={options}
    />
  );
};

export default Emp_Task_table;
