import React from "react";
import { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
const DeleteTask = (props) => {
  const { row, handleClosee,handleUpdate} = props;
  const [password, setpassword] = useState("Fino$1992");
  const [user_acess, setUser_Acess] = useState();
  const handleDelete = () => {
    // handleClose()
    const apiUrl = `https://api.startupfino.in/task-manager/api/task-name/${row.id}`;
    axios
      .delete(apiUrl)
      .then((response) => {
        console.log("Task Updated successfully:", response.data);
        handleClosee();
        handleUpdate();
        toast.success("Task Deleted Successfully");

      })
      .catch((error) => {
        console.error("Error adding task:", error);
      });
  };

  return (
    <Box
      sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
      display="flex"
    >
      <Typography
        variant="overline"
        display="block"
        gutterBottom
        color={user_acess === password ? "red" : "black"}
      >
        Alert : Following Task Name will be Deleted
      </Typography>
      <Typography align="left" color="black">
        Task Name : {row.task_name}
      </Typography>

      <Box justifyContent="center" display="flex" sx={{ margin: 2 }}>
        <TextField
          value={user_acess}
          type="password"
          onChange={(e) => {
            setUser_Acess(e.target.value);
          }}
          label="Enter Password To Delete Task"
          variant="outlined"
          sx={{ margin: 1, width: 300 }}
        />
        <Button
          variant="contained"
          color="error"
          sx={{ m: 2 }}
          disabled={user_acess === password ? false : true}
          onClick={handleDelete}
        >
          Delete Task
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteTask;
