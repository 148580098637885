import React from "react";
import { useEffect, useState } from "react";
import ClippedDrawer from "../sidebar/sidebar_list";
import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { toast } from "react-toastify";
import iconlog from "../Logo/icon.png";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Fade, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage
const Prefernce = () => {
  const [datas, setDatas] = useState([]);
  const [company_list,setcompany_list]=useState([]);
  const [company_noti, setcompany_noti] = useState([]);
  const [company_data, setcompany_Data] = useState([]);
  const [compliance_list, setCompliance_list] = useState([]);
  const [compliance_id_auto, setCompliance_id_auto] = useState();
  const [open, setOpen] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [name, setName] = useState("");
  const [openpref, setPrefOpen] = useState(false);
  const [openAddCred, setAddCred] = useState(false);
  const [active, setActive] = useState(true);
  const [compliance, setcompliance] = useState();
  const [date, setDate] = useState(moment());
  const [state, setState] = useState(null);
  const [copied, setCopied] = useState(false);
  const [ComplianceNo,setComplianceNo]=useState("");
  const [Category,setCategory]=useState("GST");
  const [Add_UserName,setAdd_UserName]=useState("");
  const [Add_Password,setAdd_Password]=useState("");
  const [Company_ID,setCompany_ID]=useState(0);
  const [url_id,seturl_id]=useSearchParams();
  const setted_id=url_id.get("company")
  const navigate = useNavigate();
  console.log("COmpanyNoti",company_noti)

  const fetchData = () => {
    axios
    .get(
      "https://api.startupfino.in/internal_compliance/company/",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      setcompany_list(response.data);
    })
    .catch((error) => {
      console.error("Error fetching contact list:", error);
    });    
    axios
      .get(
        "https://api.startupfino.in/internal_compliance/company_credential/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setcompany_Data(response.data);
        console.log("CredToMap", response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact list:", error);
      });
    axios
      .get("https://api.startupfino.in/internal_compliance/compliance/", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setCompliance_list(response.data);
      })
      .catch((error) => {
        console.error("Error fetching contact list:", error);
      });
  };
 
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  console.log("PREFU", company_noti);
  const CredMapp = company_noti?.filter((item) => item.company_name === companyName)
    ?.map((item1) => {
      const cred_data = company_data?.find(
        (item2) => item2.company === item1.company && item2.state === item1.state
      );
    
      if (cred_data) {
        return {
          ...item1,
          ...cred_data,
          copy: false,
          copy_pass: false,
        };
      }
      return item1;
    });
  console.log("CredMapp", CredMapp);

  useEffect(() => {
    const data = CredMapp?.filter(
      (item) => item.company == setted_id
    )?.map((row) => {
      return {
        companyname: row.company_name,
        client: row.name,
        state: row.state,
        frequency: row.frequency,
        compliance_name: row.compliance_name,
        edit: row,
        un: row,
        ps: row,
        cn: row.compliance_number,
      };
    });
    setDatas(data);
  }, [company_noti, companyName]);

  console.log("comooo", company_noti);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleAdd = () => {
    // Use formData to make the API request
    const postData = JSON.stringify({
      name: name,
    });
    const apiUrl = "https://api.startupfino.in/internal_compliance/company/";

    axios
      .post(apiUrl, postData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success("Added Successfully");
        fetchData();
        handleClose();
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        // Handle error as needed
      });
  };
  const HandleCopytoClip = (text, rowIndex) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log("copiedText", text);
      setCopied(true);
    });

    console.log(
      "navigator.clipboard.writeText(text);",
      navigator.clipboard.writeText(text)
    );

    setTimeout(() => setCopied(false), 10000);
  };

  const handleWhatsapp = (e, row) => {
    var waspp_noti = new FormData();
    let message;
    if (row.whatsapp_alerts == true) {
      row.whatsapp_alerts = false;
      message = `Disabling WhatsApp Alerts for \n ${row.name} at \n ${row.company_name}`;
      console.log("Update", row.whatsapp_alerts);
    } else if (row.whatsapp_alerts == false) {
      row.whatsapp_alerts = true;
      message = `Enabling WhatsApp Alerts for \n ${row.name} at \n ${row.company_name}`;
      console.log("Update", row.whatsapp_alerts);
      //
    }
    waspp_noti.append("name", row.name);
    waspp_noti.append("role", row.role);
    waspp_noti.append("email", row.email);
    waspp_noti.append("phone", row.phone);
    waspp_noti.append("whatsapp_alerts", row.whatsapp_alerts);
    console.log("check", row.whatsapp_alerts);
    const apiUrl = `https://api.startupfino.in/internal_compliance/internal-notification/${row.id}/`;
    axios
      .patch(apiUrl, waspp_noti, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success(message);
        fetchData();
        setOpen(false);
      });
  };
  const handleEmail = (e, row) => {
    var email_noti = new FormData();
    let message;
    if (row.email_alerts == true) {
      row.email_alerts = false;
      message = `Disabling Email Alerts for \n${row.name} at \n${row.company_name}`;
      console.log("Update", row.email_alerts);
    } else if (row.email_alerts == false) {
      row.email_alerts = true;
      message = `Enabling Email Alerts for \n${row.name} at \n${row.company_name}`;
      console.log("Update", row.email_alerts);
    }
    email_noti.append("name", row.name);
    email_noti.append("role", row.role);
    email_noti.append("email", row.email);
    email_noti.append("phone", row.phone);
    email_noti.append("email_alerts", row.email_alerts);
    console.log("check", row.whatsapp_alerts);
    const apiUrl = `https://api.startupfino.in/internal_compliance/internal-notification/${row.id}/`;
    axios
      .patch(apiUrl, email_noti, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success(message);
        fetchData();
        setOpen(false);
      });
  };
  const options = {
    filterType: "checkbox",
    selectableRows: false,
    searchAlwaysOpen: true,
    filterType: "multiselect",
  };
  const columns = [
    {
      name: "companyname",
      label: "Company",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "state",
      label: "State",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "compliance_name",
      label: "Compliance Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cn",
      label: "Compliance No",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "un",
      label: "User Name",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, rowData, currentTableData) => {
          console.log(
            "Copied",
            data?.copy,
            "rowData",
            rowData.currentTableData[0].data[7].copy,
            "Index",
            rowData.rowIndex
          );
          return (
            <Box display={"flex"} flexDirection={"row"} justifyItems={"center"}>
              <IconButton
                sx={{ mr: 2, cursor: "pointer" }}
                onClick={() => {
                  HandleCopytoClip(data.username);
                  rowData.currentTableData[
                    rowData.rowIndex
                  ].data[7].copy = true;
                  setTimeout(() => {
                    rowData.currentTableData[
                      rowData.rowIndex
                    ].data[7].copy = false;
                  }, 5000);
                }}
              >
                {rowData.currentTableData[rowData.rowIndex].data[7].copy ? (
                  <DoneIcon />
                ) : (
                  <ContentCopyIcon />
                )}
                <Typography>{data.username}</Typography>
              </IconButton>
            </Box>
          );
        },
      },
    },
    {
      name: "ps",
      label: "Password",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (data, rowData, currentTableData) => {
          console.log(
            "Copied",
            data?.copy_pass,
            "rowData",
            rowData.currentTableData[0].data[7].copy_pass,
            "Index",
            rowData.rowIndex
          );
          return (
            <Box display={"flex"} flexDirection={"row"} justifyItems={"center"}>
              <IconButton
                sx={{ mr: 2, cursor: "pointer" }}
                onClick={() => {
                  HandleCopytoClip(data.password);
                  rowData.currentTableData[
                    rowData.rowIndex
                  ].data[7].copy_pass = true;
                  setTimeout(() => {
                    rowData.currentTableData[
                      rowData.rowIndex
                    ].data[7].copy_pass = false;
                  }, 5000);
                }}
              >
                {rowData.currentTableData[rowData.rowIndex].data[7]
                  .copy_pass ? (
                  <DoneIcon />
                ) : (
                  <ContentCopyIcon />
                )}
                <Typography>{data.password}</Typography>
              </IconButton>
            </Box>
          );
        },
      },
    },
    {
      name: "frequency",
      label: "frequency",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "edit",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (data, dataIndex, rowIndex) => {
          return <Checkboxs data={data} />;
        },
      },
    },
  ];
  const company_opts = company_noti?.map((item) => item.company_name);
  let filter_opts = [
    ...new Set(company_noti?.map((item) => item.company_name)),
  ];
  const states = [
    "Andaman and Nicobar",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chattisgarh",
    "Dadra and Nagar Haveli & Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himanchal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshyadeep",
    "Madhya Pradesh",
    "Maharahtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Oddisaa",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const handleChecked = (event) => {
    setActive(event.target.checked);
  };
  const handleAddPref = () => {
    console.log("datas",datas)
    let data = JSON.stringify({
      "compliance_id": compliance_id_auto,
      "state": state,
      "is_active": active,
      "start_month": moment(date).format("MM"),
      "start_year": moment(date).format("YYYY")
    });
    
    console.log("postData",data)
    const apiUrl =`https://api.startupfino.in/internal_compliance/add_company_preference/${Company_ID}/`;
  
    axios
      .post(apiUrl,data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success("Added Successfully");
        fetchData();
        setPrefOpen(false)
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        // Handle error as needed
      });
  };
  
  const handleAddCred = () => {
    var payload = JSON.stringify({
      "company_name": companyName,
      "username": Add_UserName,
      "password": Add_Password,
      "state": state,
      "category":Category,
      "compliance_number":ComplianceNo,
      "company": Company_ID
    })
    
    const apiUrl =
      "https://api.startupfino.in/internal_compliance/company_credential/";

    axios
      .post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("successfully:", response.data);
        toast.success("Added Successfully");
        fetchData();
        setAddCred(false)
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        toast.error("Error in Adding Credentials");

        // Handle error as needed
      });
  };
  useEffect(()=>{
    const sid = company_data?.filter((item)=>item.company==setted_id)
    if(sid.length>0){
      setCompanyName(sid[0].company_name)
      console.log("sid",sid[0]?.company_name,setted_id)
    } 
  })

  return (
    <Box sx={{ display: "flex" }}>
      <ClippedDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pr: 3,
          pt: 2,
          pl: 2,
          width: "100%",
          overflowX: "auto",
        }}
      >
        <Button variant="contained" sx={{ mb: 2 }} onClick={handleClickOpen}>
          Add New Company
        </Button>
        <Grid container>
          <Grid xs={5}>
            <Autocomplete
              label="Company Name"
              id="combo-box-demo"
              value={companyName}
              onChange={(e, newVal) => {
                setCompanyName(newVal);
                let id = company_list?.filter((item)=>item.name===newVal)
                setCompany_ID(id[0]?.id)
                console.log("mynewid",id[0])
                navigate(`/preference?company=${id[0]?.id}`)
                axios
                .get(
                  `https://api.startupfino.in/internal_compliance/company_preference/?company=${id[0]?.id}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${accessToken}`,
                    },
                  }
                )
                .then((response) => {
                  setcompany_noti(response.data);
                  console.log("PrefDATA", response.data);
                })
                .catch((error) => {
                  console.error("Error fetching contact list:", error);
                });
            
              }}
              options={company_list?.map((item)=>item.name)}
              renderInput={(params) => (
                <TextField {...params} label="Company Name" value={setted_id?"tre":""}/>
              )}
            />
          </Grid>
          {companyName && (
            <>
              <Grid md={7}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ m: 1 }}
                  onClick={() => setPrefOpen(true)}
                >
                  Add Prefernce
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => setAddCred(true)}
                >
                  Add Company Credentials
                </Button>
              </Grid>
              <Grid lg={3} md={3}></Grid>
            </>
          )}
        </Grid>
        <br />
        <MUIDataTable
          title={"Client Notification List"}
          data={datas}
          columns={columns}
          options={options}
        />
        <Dialog
          open={open}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
        >
          {" "}
          <DialogTitle>Add Companys</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <br></br>
                <TextField
                  label="Companys Name"
                  name="name"
                  value={name}
                  onChange={(e, val) => {
                    setName(e.target.value);
                  }}
                  fullWidth
                  margin="normal"
                  sx={{ pr: 1 }}
                />
              </Grid>
            </Grid>
            {/* Add other text fields for each property in formData */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="success"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleAdd()}
            >
              Add Company
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openpref}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
        >
          {" "}
          <DialogTitle>Add Preference for {companyName}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <br></br>
                <Autocomplete
                  sx={{ m: 1 }}
                  label="Company Type"
                  fullWidth
                  id="combo-box-demo"
                  value={compliance}
                  onChange={(e, newVal) => {
                    setcompliance(newVal);
                    console.log("compliance_list", newVal.value);
                    let cmp_id = compliance_list?.filter(
                      (item) =>
                        item.name === newVal.value.name &&
                        item.frequency === newVal.value.freq
                    );

                    console.log("cmp_id", cmp_id[0]?.id);
                    setCompliance_id_auto(cmp_id[0]?.id);
                  }}
                  options={compliance_list?.map((item) => ({
                    label: `${item.name} (${item.frequency})`,
                    value: { name: item.name, freq: item.frequency },
                  }))}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Compliance" />
                  )}
                />

              </Grid>
              <Grid xs={6} lg={6} md={6}>
                <Autocomplete
                  sx={{ m: 1 }}
                  label="Company Type"
                  id="combo-box-demo"
                  value={state}
                  onChange={(e, newVal) => {
                    setState(newVal);
                  }}
                  options={states}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose State" />
                  )}
                />
              </Grid>
              <Grid xs={6} lg={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    name="date"
                    label="Date of Incorporation"
                    format="DD/MM/YYYY"
                    value={date}
                    sx={{ m: 1, ml: 1 }}
                    onChange={(e, newTo_date) => {
                      setDate(moment(newTo_date));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ margin: 1 }} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <Box display={"flex"} flexDirection={"row"} sx={{ m: 1 }}>
                    <Typography sx={{ mt: 1 }}>
                      Do you want to keep this Preference Active?
                    </Typography>
                    <Checkbox
                      checked={active}
                      onChange={(e) => handleChecked(e)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* Add other text fields for each property in formData */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="success"
              onClick={() => setPrefOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {handleAddPref()}}
            >
              Add Prefernce
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add Cred */}
        <Dialog
          open={openAddCred}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
        >
          {" "}
          <DialogTitle>Add Credentials for {companyName}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <br></br>
                <TextField
                  label="Compliance Number"
                  value={ComplianceNo}
                  onChange={(e)=>{setComplianceNo(e.target.value)}}

                  fullWidth
                  sx={{ml:1,pr:2,mt:1,mb:1}}
                />
              </Grid>
              <Grid xs={6} lg={6} md={6}>
                <Autocomplete
                  sx={{ m: 1 }}
                  label="Company Type"
                  id="combo-box-demo"
                  value={state}
                  onChange={(e, newVal) => {
                    setState(newVal);
                  }}
                  options={states}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose State" />
                  )}
                />
              </Grid>
              <Grid xs={6} lg={6} md={6}>
                <Autocomplete
                  sx={{ m: 1 }}
                  label="Category"
                  id="combo-box-demo"
                  defaultValue={"GST"}
                  value={"GST"}
                  disabled={true}
                  options={["GST", "IT", "PF"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
              </Grid>
              <Grid container>

                <Grid xs={6} md={6}>
    
                <TextField
                  label="Username"
                  value={Add_UserName}
                  onChange={(e)=>{setAdd_UserName(e.target.value)}}
                  sx={{ml:1,pr:2,mt:1}}
                  fullWidth
                />
                </Grid>
                <Grid xs={6} md={6}>
                <TextField
                  label="Password"
                  value={Add_Password}
                  onChange={(e)=>{setAdd_Password(e.target.value)}}
                  sx={{ml:1,pr:2,mt:1}}
                  fullWidth
                />
                </Grid>
              </Grid>
 
           
            </Grid>
            {/* Add other text fields for each property in formData */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="success"
              onClick={() => setAddCred(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {handleAddCred()}}
            >
              Add Credentials
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Prefernce;
const Checkboxs = (props) => {
  const { data } = props;
  console.log("Datapassed", data);
  const [checked, setChecked] = useState(data?.is_active);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    console.log("checked", event.target.checked);
    var update_pref = JSON.stringify({
      "compliance_id":data.compliance ,
      "state": data.state,
      "is_active": event.target.checked,
      "start_month": moment(data.created_at).format("MM"),
      "start_year": moment(data.created_at).format("YYYY")
    });
    
    
    const apiUrl =`https://api.startupfino.in/internal_compliance/add_company_preference/${data?.company}/`;
    axios.post(apiUrl, update_pref, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        toast.success("Updated Preference");
      });
  };
  return (
    <Checkbox
      checked={checked}
      onChange={(e) => handleChange(e)}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};
