import React from "react";
import { Grid } from "@mui/material";
import {CircularProgress} from "@mui/material";
import logos from "../Logo/loader.png"
const Loader = () => {
  return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "70vh", position: "relative" }}
      >
        <Grid item sx={{ position: "absolute", zIndex: 2 }}>
          <CircularProgress color="info" size={90} />
        </Grid>
        <Grid item sx={{ position: "absolute", zIndex: 1 }}>
          <img src={logos} alt="Logo" />
        </Grid>
      </Grid>
  );
};

export default Loader;
