import React from "react";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
// https://api.startupfino.in/company/all-companies/
import axios from "axios";
import { useEffect } from "react";
import { Chip, Box } from "@mui/material";
import { toast } from "react-toastify";

import { Typography, TextField } from "@mui/material";
import moment from "moment";
import ClippedDrawer from "../sidebar/sidebar_list";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useConfirm } from "material-ui-confirm";
import { ConfirmProvider } from "material-ui-confirm";
import { Tooltip } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { CircularProgress } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import logo from "../Logo/loader.png";
import Recurring_Add_Data from "../Recurring_Task/Recurring_Add";
import Edit_Recurring_Data from "../Recurring_Task/Edit_Recurring_Task";
import Delete_Recurring_Data from "../Recurring_Task/Delete_Reccuring";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  AppBar,
  Toolbar,
  Drawer,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import Trigger_All from "./Trigger_All";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
const Add_Contact = () => {
  const [opene, setOpene] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [all_companies, setall_companies] = useState([]);
  const [all_task_name, setall_task_name] = useState([]);
  const [all_team_members, setall_team_members] = useState([]);
  const [client_mails, setClient_mails] = useState([]);
  const [signedInMate, setSignedSignMate] = useState("");
  const userId = localStorage.getItem("user_id");
  const [notifications, setNotifications] = useState([]);
  const [company_data, setCompany_data] = useState([]);
  const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage
  console.log("accessToken", accessToken);
  const handleClickOpene = (scrollType) => () => {
    setOpene(true);
    setScroll(scrollType);
  };
  const handleClosee = () => {
    setOpene(false);
  };
  const datasetBSet = new Set(notifications?.map((id) => id.companyId.id));
  const uniqueCompaniesInA = company_data?.filter(
    (item) => !datasetBSet.has(item.id)
  );
  console.log("unique", uniqueCompaniesInA);
  console.log("dataB", datasetBSet);
  const columns = [

    {
      name: "update",
      label: "Update",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRender: (data, dataIndex, rowIndex) => {
          return (
            <>
              <Box textAlign="center">
                <Update data={data} handleUpdate={handleUpdate} />
                {/* <WhatsApp_Checkbox /> */}
              </Box>
            </>
          );
        },
      },
    },

    {
      name: "name",
      label: "Company",
      options: {
        filter: true,
        sort: true,
      },
    },

  ];
  // wrte code for api call using axios
  const [datas, setData] = useState([]);
  const [table_datas, settable_Data] = useState([]);
  function parseFrequency(freqStr) {
    const freqPattern = /FREQ=([^;]+)BYDAY=([^;]+)/;
    const matches = freqStr.match(freqPattern);
    if (!matches || matches.length < 3) {
      throw new Error("Invalid frequency string");
    }
    const freq = matches[1];
    const day = matches[2];
    return { freq, day };
  }
  useEffect(() => {
    if (accessToken) {
      const instance = axios.create({
        baseURL: "https://api.startupfino.in", // API base URL
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      instance
        .get("/company/notifications-list/")
        .then((response) => {
          setNotifications(response.data); // Update state with fetched data
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      const instance2 = axios.create({
        baseURL: "https://api.startupfino.in", // API base URL
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      instance2
        .get("/company/all-companies/")
        .then((response) => {
          setCompany_data(response.data); // Update state with fetched data
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [accessToken]);
  console.log("notifications", notifications);
  console.log("company_data", company_data);

  const handleUpdate = () => {
    setLoading(true);
    if (accessToken) {
      const instance = axios.create({
        baseURL: "https://api.startupfino.in", // API base URL
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      instance
        .get("/company/notifications-list/")
        .then((response) => {
          setNotifications(response.data); // Update state with fetched data
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (all_team_members.length > 0) {
      const selectUser = all_team_members.filter((item) => {
        return item.user_id === parseInt(userId);
      });
      if (selectUser.length > 0) {
        const filterTask = datas.filter((task) => {
          return (
            task.task_owner_id === selectUser[0].id ||
            task.task_assignee_id === selectfUser[0].id
          );
        });
        setData(filterTask);
      }
      if (selectUser.length === 0 && userId != 402) {
        setData([]);
      }
    }
  }, [all_team_members, datas]);
  const opts = all_companies?.map((option) => option.company_name);
  const tsk_name_opts = all_task_name.map((option) => option.task_name);
  const team_mem_opts = all_team_members.map((option) => option.name);
  const client_mail_opts = client_mails.map((option) => option.client_mail);

  console.log("client_mail_opts", client_mail_opts);

  console.log("all companies", datas);

  //write code for maping data from api to table data
  useEffect(() => {
    const data = uniqueCompaniesInA?.map((row) => {
      return {
        name: row.company_name,
        update: row,
      };
    });
    console.log("Data", data);
    settable_Data(data);
  }, [notifications]);

  console.log(table_datas, "reccu_Data");
  const data = [
    { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
    { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
    { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
    {
      name: "James Houston",
      company: "Test Corp",
      city: "Dallas",
      state: "TX",
    },
  ];

  const options = {
    filterType: "multiselect",
    selectableRows: false,
    rowsPerPageOptions: [10, 100, 1000, 1500, 2000],
    rowsPerPage: 1000,
    responsive: "standard",
    fixedSelectColumn: true,
    tablebodywidth: "auto",
    searchAlwaysOpen:true
  };
  let comp_task = datas?.filter((x) => x.task_status.includes("Completed"));
  let pend_task = datas?.filter((x) => !x.task_status.includes("Cancelled"));
  console.log("datas", datas);

  return (
    // <TestingAuto/>
    <>
      {loading ? (
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "70vh", position: "relative" }}
          >
            <Grid item sx={{ position: "absolute", zIndex: 2 }}>
              <CircularProgress color="info" size={90} />
            </Grid>
            <Grid item sx={{ position: "absolute", zIndex: 1 }}>
              <img src={logo} alt="Logo" />
            </Grid>
          </Grid>
        </>
      ) : (

          <MUIDataTable
            title={"Add_Contact"}
            data={table_datas}
            columns={columns}
            options={options}
          />
      )}
    </>
  );
};

export default Add_Contact;
const Update = (props) => {
  const { data, handleUpdate } = props;
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const accessToken = localStorage.getItem("api_token"); // Retrieve token from local storage

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const [role, setRole] = useState([
    {
      userName: "",
      userName: "",
      userRole: "",
      userMobile: "",
    },
  ]);
  const [namee, setNamee] = useState(data?.name);
  const [id, setId] = useState(data?.id);
  const [rolee, setRolee] = useState(data?.role);
  const [phonee, setPhonee] = useState(data?.phone);
  const [emaile, setEmaile] = useState(data?.email);

  // console.log("data",namee,rolee,phonee,emaile)
  const handleRoleDataChange = (e) => {
    const { name, value } = e.target;
    const roleData = [...role];
    roleData[0][name] = value;
    setRole(roleData);
  };
  const handleClosee = () => {
    setOpen(false);
  };
  const dis = (e) => {
    console.log("Data", role);
    // dispatch(updateNoti(bodyFormData, id));
    const apiUrl = `https://api.startupfino.in/company/notifications/${data?.id}/`;
    axios
      .post(apiUrl, role, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("Updated successfully:", response.data);
        toast.success("Updated successfully");
        handleUpdate();
        handleClosee();
      })
      .catch((error) => {
        // Handle errors that occur during the request
        console.error("Error adding task:", error);
      });
    setOpen(false);
  };

  return (
    <>
      <IconButton color="info" onClick={handleClickOpen("paper")}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        fullWidth={true}
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            component="span"
            m={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {`${data?.company_name}-Update`}
            <CloseRoundedIcon
              onClick={() => setOpen(false)}
              style={{ color: "#ff5252" }}
              boxShadow={1}
            />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <Box
              sx={{
                margin: 1,
                justifyContent: "center",
                flexDirection: "column",
              }}
              display="flex"
            >
              <TextField
                fullWidth
                label="Name"
                name="userName"
                value={role[0].userName}
                onChange={handleRoleDataChange}
                required
                sx={{m:1}}
              />
              <TextField
                fullWidth
                label="Role"
                name="userRole"
                value={role[0].userRole}
                onChange={handleRoleDataChange}
                required
                sx={{m:1}}
              />
              <TextField
                type="email"
                fullWidth
                label="Email"
                name="userEmail"
                value={role[0].userEmail}
                onChange={handleRoleDataChange}
                required
                sx={{m:1}}
              />
              <TextField
                fullWidth
                type="tel"
                label="Mobile no"
                name="userMobile"
                value={role[0].userMobile}
                onChange={handleRoleDataChange}
                required
                sx={{m:1}}
              />
              <Box
                container
                m={1}
                display="flex"
                justifyContent="center"
                alignItems="flex-end"
                sx={{ margin: 1 }}
              >
                <Button type="submit" onClick={dis} variant="contained">
                  SAVE
                </Button>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

const WhatsApp_Checkbox = (props) => {
  const { data } = props;
  const accessToken = localStorage.getItem("api_token");
  const [checked, setChecked] = useState(data?.whatsapp_alerts);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    var waspp_noti = new FormData();
    waspp_noti.append("userName", data?.name);
    waspp_noti.append("userRole", data?.role);
    waspp_noti.append("userEmail", data?.email);
    waspp_noti.append("userPhone", data?.phone);
    const apiUrl = `https://api.startupfino.in/company/notifications/notification/${data?.id}/`;
    axios
      .patch(apiUrl, waspp_noti, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("WhatsApp successfully:", response.data);
        toast.success("WhatsApp Updated successfully");
      })
      .catch((error) => {
        // Handle errors that occur during the request
        console.error("Error adding task:", error);
      });
  };
  return (
    <>
      <Checkbox checked={checked} onChange={handleChange} />
    </>
  );
};
const Email_Checkbox = (props) => {
  const { data } = props;
  const [checked, setChecked] = useState(data?.email_alerts);
  const accessToken = localStorage.getItem("api_token");
  const handleChange = (event) => {
    setChecked(event.target.checked);
    var waspp_noti = new FormData();
    waspp_noti.append("name", data?.name);
    waspp_noti.append("role", data?.role);
    waspp_noti.append("email", data?.email);
    waspp_noti.append("phone", data?.phone);
    waspp_noti.append("email_alerts", event.target.checked);
    console.log("check", event.target.checked);
    const apiUrl = `https://api.startupfino.in/company/notifications/notification/${data?.id}/`;
 
  };
  return (
    <>
      <Checkbox checked={checked} onChange={handleChange} />
    </>
  );
};
