import React from "react";
import { useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";

const Edit_Task = (props) => {
  const { handleClosee ,row,handleUpdate} = props;
  const [formData, setFormData] = useState({
    task_name: "",
  });
  console.log('row',row)
  const [Fname, setFname] = useState();
  const [taskName, setTakeName] = useState(row.task_name);
  let name, valuee;
  const handleFormDataChange = (e) => {
    name = e.target.name;
    valuee = e.target.value;
    setFormData({ ...formData, [name]: valuee });
  };
  const dis = (e) => {
    var bodyFormData = new FormData();
    for (let key in formData) {
      if ((key, formData[key] !== '')) {
        bodyFormData.append(key, formData[key]);
      }
    }
    const apiUrl = `https://api.startupfino.in/task-manager/api/task-name/${row.id}`;
    axios
      .patch(apiUrl,bodyFormData)
      .then((response) => {
        console.log("Task Updated successfully:", response.data);
        handleClosee();
        handleUpdate();
        toast.success("Task Updated Successfully");
    
      })
      .catch((error) => {
        console.error("Error adding task:", error);
      });
  };
  

  return (
    <>
      <Box
        sx={{ margin: 1, justifyContent: "center", flexDirection: "column" }}
        display="flex"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} xl={12}>
            <TextField
              name="task_name"
              onChange={(e) => {
                setTakeName(e.target.value);
                {
                  formData.task_name = e.target.value;
                }
                {
                  handleFormDataChange;
                }
              }}
              value={taskName}
              label="Task Name"
              variant="outlined"
              sx={{ margin: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        container
        m={1}
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        sx={{ margin: 1 }}
      >
        <Button onClick={dis} variant="contained">
          Update Task Name
        </Button>
      </Box>
    </>
  );
};

export default Edit_Task;
